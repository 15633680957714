import React from "react";

import { SliderItem } from "./SliderItem";

export const Slider2 = ({ image, title, scopes, results, show }) => {
  console.log('scopes',show)
  return (
    <div className="slider" hidden={!show}>
      <div className="slider-body">
        <p className="slider-subtitle" style={{ color: "#2E335E" }}>
          {title}{" "}
        </p>
        <p className="slider-subtitle" style={{ color: "#EF8B44" }}>
          Principales resultados
        </p>
        <div className="slider-info">
          {results.map((result) => (
            <SliderItem key={result.key} {...result} />
          ))}
        </div>
        <div className="slider-body2">
          <p className="slider-subtitle" style={{ color: "#2e335e" }}>
            Alcances
          </p>
          <div className="slider-info">
            {scopes.map((scope) => (
              <SliderItem key={scope.key} {...scope} />
            ))}
          </div>
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};

import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./tips.css";
import { auth, db } from "../../../../components/firebase/firebase";
import { useCuidadorEntries, _useCuidadorTipsByArea } from "../../../../customHooks"
import { Orbitals } from "react-spinners-css";

import arrTips from "../../../../data";

const TipsHigiene = ({
  contenidoFirebase,
  error,
  loading,
  firebaseUser,
  idChild,
}) => {
  let history = useHistory();
  const [cuidador,] = _useCuidadorTipsByArea("areasDelCuidador", { 'fields.nombreArea': "Higiene y Agua" })
  return (
    <div>
      {firebaseUser !== null ? (
        <div className="background-tips-higiene" id="aprendamos-page">
          <div className=" animated fadeIn">
            {/* {error && <strong>Error: {JSON.stringify(error)}</strong>}
            {loading && (
              <div className="grande">
                <div className="centrando-spiner">
                  <Orbitals color="#EF8B44" size={900} />
                </div>{" "}
              </div>
            )}*/}
            {/*{contenidoFirebase && ( */}
            <>
              <a
                onClick={() => {
                  history.goBack();
                }}
              >
                <i
                  className="fas fa-chevron-left arrowGoback"
                  aria-hidden="true"
                ></i>
              </a>

              {cuidador ? cuidador
                .filter(
                  (item, index) =>
                    index == localStorage.getItem("localNumberTip")
                )
                .map((item) => (
                  <div className="" key={item}>
                    <div className="row justify-content-center h-100 sin-margin-row">
                      <h1 className="tittle-tips tracking-in-expand-fwd-top">
                        {item.fields.nombreDelTip}
                      </h1>
                    </div>
                    <div className="row justify-content-center  h-100 sin-margin-row">
                      <h3 className="tips-numbers tracking-in-expand-fwd-top">
                        TIP N°{parseInt(localStorage.getItem("localNumberTip")) + 1}
                      </h3>
                    </div>
                    <div className="row tips-margin-top sin-margin-row tips-1-columna">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {!item.fields.urlVideo ? (

                          item.fields.imagenDelTip ?
                            <img
                              src={"https:" + item.fields.imagenDelTip.fields.file.url}
                              /*src={require(`../../../../images/icono-blanco/0-1/batea_de_ropa_Mesa de trabajo 1.svg`)}*/
                              alt=""
                              className="iconos-tips-blanco heartbeat"
                            /> :
                            <img
                              src={require(`../../../../images/noimage.png`)}
                              /*src={require(`../../../../images/icono-blanco/0-1/batea_de_ropa_Mesa de trabajo 1.svg`)}*/
                              alt=""
                              className="iconos-tips-blanco heartbeat"
                            />
                        ) : (
                          <div className="videoWrapper">
                            <iframe
                              className="border-video-higiene"
                              width="600"
                              height="350"
                              src={item.fields.urlVideo}
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        <div className="text-tips">
                          {JSON.parse(localStorage.getItem("edadChildLogueadoActive")) == -1 ? (
                            <h3>
                              Estos consejos están dirigidos a la gestante de tu
                              familia:
                            </h3>
                          ) : (
                            <h3>
                              Hola {localStorage.getItem("nameUserActive")}
                            </h3>
                          )}
                          <p className="text-tips-light">
                            {item.fields.primerParrafo.replace("{{hijo}}", localStorage.getItem("nameChildActive"))}
                          </p>
                          {/* {item.texto1B !== "" && item.texto1A !== "" ? (
                            <p class="text-tips-light">
                              {item.texto1A}
                              {localStorage.getItem("nameChildActive")}
                              {item.texto1B}
                            </p>
                          ) : item.texto1B !== "" && item.texto1A === "" ? (
                            <p class="text-tips-light">{item.texto1B}</p>
                          ) : (
                            <p class="text-tips-light">
                              {localStorage.getItem("nameChildActive")}{" "}
                              {item.texto1A}
                            </p>
                          )} */}
                          <p className="text-tips-bold">{item.fields.segundoParrafo}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )) : []}
            </>
            {/*  )} */}
          </div>
        </div>
      ) : (
        <div className="background-tips-higiene" id="aprendamos-page">
          <div className=" animated fadeIn">
            <>
              <a
                onClick={() => {
                  history.goBack();
                }}
              >
                <i
                  className="fas fa-chevron-left arrowGoback"
                  aria-hidden="true"
                ></i>
              </a>
              {cuidador ? cuidador
                .filter(
                  (item, index) =>
                    index == localStorage.getItem("localNumberTip")
                )
                .map((item) => (
                  <div className="">
                    <div className="row justify-content-center h-100 sin-margin-row">
                      <h1 className="tittle-tips tracking-in-expand-fwd-top">
                        {item.fields.nombreDelTip}
                      </h1>
                    </div>
                    <div className="row justify-content-center  h-100 sin-margin-row">
                      <h3 className="tips-numbers tracking-in-expand-fwd-top">
                        TIP N°{parseInt(localStorage.getItem("localNumberTip")) + 1}
                      </h3>
                    </div>
                    <div className="row tips-margin-top sin-margin-row tips-1-columna">
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {!item.fields.urlVideo ? (

                          item.fields.imagenDelTip ?
                            <img
                              src={"https:" + item.fields.imagenDelTip.fields.file.url}
                              /*src={require(`../../../../images/icono-blanco/0-1/batea_de_ropa_Mesa de trabajo 1.svg`)}*/
                              alt=""
                              className="iconos-tips-blanco heartbeat"
                            /> :
                            <img
                              src={require(`../../../../images/noimage.png`)}
                              /*src={require(`../../../../images/icono-blanco/0-1/batea_de_ropa_Mesa de trabajo 1.svg`)}*/
                              alt=""
                              className="iconos-tips-blanco heartbeat"
                            />
                        ) : (
                          <div className="videoWrapper">
                            <iframe
                              className="border-video-higiene"
                              width="600"
                              height="350"
                              src={item.fields.urlVideo}
                              frameBorder="0"
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowfullscreen
                            ></iframe>
                          </div>
                        )}
                      </div>
                      <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
                        {JSON.parse(localStorage.getItem("dateChild")).edad ==
                          -1 ? (
                          <div className="text-tips">
                            <h3>
                              Estos consejos están dirigidos a la gestante de tu
                              familia:
                            </h3>
                            <p className="text-tips-light">{item.fields.primerParrafo.replace("{{hijo}}", JSON.parse(localStorage.getItem("dateChild")).name)}</p>
                            <p className="text-tips-bold">{item.fields.segundoParrafo}</p>
                          </div>
                        ) : (
                          <div className="text-tips">
                            <h3>Hola</h3>
                            <p className="text-tips-light">
                              {item.fields.primerParrafo.replace("{{hijo}}", JSON.parse(localStorage.getItem("dateChild")).name)}
                            </p>
                            {/* {item.texto1B !== "" && item.texto1A !== "" ? (
                              <p class="text-tips-light">
                                {item.texto1A}
                                {
                                  JSON.parse(localStorage.getItem("dateChild"))
                                    .name
                                }
                                {item.texto1B}
                              </p>
                            ) : item.texto1B !== "" && item.texto1A === "" ? (
                              <p class="text-tips-light">{item.texto1B}</p>
                            ) : (
                              <p class="text-tips-light">
                                {
                                  JSON.parse(localStorage.getItem("dateChild"))
                                    .name
                                }{" "}
                                {item.texto1A}
                              </p>
                            )} */}
                            <p className="text-tips-bold">{item.fields.segundoParrafo}</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )) : []}
            </>
          </div>
        </div>
      )}
    </div>
  );
};

export default TipsHigiene;

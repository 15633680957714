import React from "react";
import { getContentfulData, getContentfulDataEntries } from "./utils"

const HOME_ENTRY_ID = '5ToiR7zEfhzyr8hcgi2lFZ'

export const useHomePageHook = () => {
  const [homePage, setHomePage] = React.useState({})

  React.useEffect(() => {
    (async () => {
      const data = await getContentfulData(HOME_ENTRY_ID)
      setHomePage(data)
    })()
  }, [])

  return [homePage, setHomePage]

}

// retrieve all the entries basde on the contentype "Areas Cuidador"

export const useCuidadorEntries = (IdAreaCuidador, filter = {}) => {
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    (async () => {
      const data = await getContentfulDataEntries(IdAreaCuidador, filter)
      setData(data)
    })()
  }, [])

  return [data, setData]

}

// retrieve all the entries basde on the contentype "Areas Cuidador"

export const _useCuidadorTipsByArea = (IdAreaCuidador, filter = {}) => {
  const [data, setData] = React.useState(null)
  // get the current date from localStorage either with a logged or not logged  user
  // we stringify because in number 0 || 1 takes the 1
  const dateChild = JSON.parse(localStorage.getItem("dateChild")) !== null ? JSON.parse(localStorage.getItem("dateChild")).edad.toString() : ''
  const edadChildLogueadoActive = JSON.parse(localStorage.getItem("edadChildLogueadoActive")) !== null ? JSON.parse(localStorage.getItem("edadChildLogueadoActive")).toString() : ''
  const currentDate = edadChildLogueadoActive.toString() || dateChild.toString()

  React.useEffect(() => {
    (async () => {
      const data = await getContentfulDataEntries(IdAreaCuidador, { ...filter, 'fields.edad': parseInt(currentDate) })
      data.items[0] ? setData(data.items[0].fields.tips) : setData(null)
    })()
  }, [])

  return [data, setData]

}

export const useAliados = (contentypeId, filter = {}) => {
  const [data, setData] = React.useState(null)
  const idRol = JSON.parse(localStorage.getItem("rol")) !== null ? parseInt(JSON.parse(localStorage.getItem("rol")).rolIndex) : null;
  const nameRol = JSON.parse(localStorage.getItem("rol")) !== null ? JSON.parse(localStorage.getItem("rol")).rolName.toString() : ''

  React.useEffect(() => {
    (async () => {
      const data = await getContentfulDataEntries(contentypeId, { ...filter, 'fields.index': idRol })
      data.items[0].fields.modulos ? setData(data.items[0].fields.modulos) : setData(null)
    })()
  }, [])

  return [data, setData]
}

export const useMaterialesEducativos = (contentypeId, filter = {}) => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const data = await getContentfulDataEntries(contentypeId, { ...filter })
      data.items[0].fields.materialEducativo ? setData(data.items[0].fields.materialEducativo) : setData(null)
    })()
  }, [])

  return [data, setData]
}


export const useGradoEducativo = (contentypeId, filter = {}) => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const data = await getContentfulDataEntries(contentypeId, { ...filter })
      setData(data)
      {/* data.items[0].fields.materialEducativo ? setData(data.items[0].fields.materialEducativo) : setData(null) */ }
    })()
  }, [])

  return [data, setData]
}

export const useCuentaCuentos = (entryID) => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const data = await getContentfulData(entryID)
      setData(data)
    })()

  }, [])

  return [data, setData]
}

export const useNosotros = (entryID) => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const data = await getContentfulData(entryID)
      setData(data)
    })()

  }, [])

  return [data, setData]
}

export const useCovid19Consejos = (entryID) => {

  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const data = await getContentfulData(entryID)
      setData(data)
    })()

  }, [])

  return [data, setData]

}

export const usePopup = (entryID) => {

  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const data = await getContentfulData(entryID)
      setData(data)
    })()

  }, [])

  return [data, setData]

}

import { client } from './contentful'
import React from "react";
import karina from "./images/equipo/karina_7.png"

export const getContentfulData = async (entryId) => {
  try {
    const { fields, metada, sys } = await client.getEntry(entryId)
    console.log(fields, 'fields')
    return fields
  } catch (error) {
    console.log("error", error)
  }
}

// retrieves multiple entreis by contentTypename
export const getContentfulDataEntries = async (contentTypeID, filter = {}) => {
  try {
    const data = await client.getEntries({ ...{ content_type: contentTypeID, include: 3 }, ...filter })
    return data
  } catch (error) {
  }
}

// with this recursive function we're creating a group of 3 phots , grouped in a div neccesarry for the carousel
export const recursivePhotoGroup = function(arr) {
  // this array will be our final content to render in React
  const carouselPhoto = []
  let first = true

  function recursiveFunc(arr) {
    console.log(carouselPhoto)
    if (arr.length === 0) {
      return
    }
    if (arr.length < 3) {
      const carouselItem = (
        <div className={"carousel-item " + (first ? "active" : "")}>
          <div className="row"> {arr.map((value) => {
            return (
              <div className="col-md-4 clearfix d-none d-md-block">
                <div className="card mb-2">
                  <img className="card-img-top" src={value.fields.foto.fields.file.url} alt="Card image cap" />
                </div>
              </div>
            )

          })}
          </div> </div>
      )
      first = false
      carouselPhoto.push(carouselItem)
      return
    } else {
      const carouselItem = (
        <div className={"carousel-item " + (first ? "active" : "")}>
          <div className="row">
            {arr.slice(0, 3).map((value) => {
              return (
                <div className="col-md-4 clearfix d-none d-md-block">
                  <div className="card mb-2">
                    <img className="card-img-top" src={value.fields.foto.fields.file.url} alt="Card image cap" />
                  </div>
                </div>
              )

            })}
          </div>
        </div>
      )

      carouselPhoto.push(carouselItem)
      first = false
      recursiveFunc(arr.slice(3))
    }

  }

  recursiveFunc(arr)


  return carouselPhoto
}

export const recursivePhotoGroupMobile = function(arr) {
  // this array will be our final content to render in React
  const carouselPhoto = []
  let first = true

  function recursiveFunc(arr) {
    if (arr.length === 0) {
      return
    }
    if (arr.length < 2) {
      const carouselItem = (
        <div className={"carousel-item " + (first ? "active" : "")}>
          <div className="row"> {arr.map((value) => {
            return (
              <div className="col-md-12">
                <div className="card mb-2 fabiola">
                  <img className="card-img-top" src={value.fields.foto.fields.file.url} alt="Card image cap" />
                </div>
              </div>
            )

          })}
          </div> </div>
      )
      first = false
      carouselPhoto.push(carouselItem)
      return
    } else {
      const carouselItem = (
        <div className={"carousel-item " + (first ? "active" : "")}>
          <div className="row">
            {arr.slice(0, 1).map((value) => {
              return (
                <div className="col-md-12">
                  <div className="card mb-2">
                    <img className="card-img-top" src={value.fields.foto.fields.file.url} alt="Card image cap" />
                  </div>
                </div>
              )

            })}
          </div>
        </div>
      )

      carouselPhoto.push(carouselItem)
      first = false
      recursiveFunc(arr.slice(3))
    }

  }

  recursiveFunc(arr)


  return carouselPhoto
}

export const recursiveProcessContenido = (arr) => {
  const equipos = []

  function recursiveFunc(arr) {

    if (arr.length === 0) {
      return

    }

    if (arr.length < 2) {
      equipos.push(arr)
      return
    } else {

      equipos.push(arr.slice(0, 2))
      recursiveFunc(arr.slice(2))
    }

  }

  recursiveFunc(arr)

  return equipos

}


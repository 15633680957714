import React from "react";
import { Link } from "react-router-dom";

import "./nosotros.css";
import { Modular } from "./Modular";
import modular_content from "../../content/modular.json";

import info1 from "../../images/nosotros-img/info-1.png";
import crecer from "../../images/nosotros-img/crecer.svg";
import agua from "../../images/nosotros-img/agua-segura.svg";
import starsLeftDesktop from "./../../images/stars-left-desktop.svg";
import starsRightDesktop from "./../../images/stars-right-desktop.svg";
import starsRight from "../../images/stars-right.svg";
import arrowLeft from "../../images/arrow-left-orange.svg";
import arrowRigth from "../../images/arrow-right-orange.svg";
import cohete from "../../images/nosotros-img/Rocketorange.svg";
import line from "../../images/nosotros-img/line.svg";
import lineVerticall from "../../images/nosotros-img/line-verticall.svg";
import { useNosotros } from "../../customHooks"
const Nosotros = () => {
  const [data, setData] = useNosotros('7zWW2Zr8ivXROS7r97o6Np')
  console.log('Nosotros', data)
  return (
    <div id="nosotros-page">
      <div id="tittle-nosotros" className="box-title animated fadeIn mt-3">
        <figure className="stars-group">
          <img
            src={starsLeftDesktop}
            className="stars-left-desktop heartbeat"
            alt="starsLeftDesktop"
          />
          <img
            src={starsRightDesktop}
            className="stars-right-desktop heartbeat"
            alt="starsRightDesktop"
          />
        </figure>
        <img
          src={starsRight}
          className="left-star heartbeat"
          alt="stars-left"
        ></img>
        <h1 className="title title-nosotros tracking-in-expand-fwd-top">
          nosotros
        </h1>
        <img
          src={starsRight}
          className="right-star heartbeat"
          alt="stars-right"
        ></img>
      </div>
      <div className="mt-3 video">
        <div className="video-responsive">
          <iframe
            src="https://www.youtube.com/embed/_rAy16B7eJs?rel=0;mute=1;autoplay=1"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          {/* <iframe  src="https://www.youtube.com/watch?v=nNdYfFO10d8&feature=youtu.be&t=1s" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
        </div>
        <p className="text-white name-video">Video Volar - ¿Quiénes somos? </p>
        <div className="container">
          <div className=" p-mobile-blue">
            {data && data.overview.content.map((p) => <p>{p.content[0].value}</p>)}
            {/* <p>
              Desde su creación, el proyecto VOLAR fue concebido como transversal, sostenible y escalable, enfocado en ofrecer a
              las familias de nuestras zonas de influencia la oportunidad de que
              sus hijas e hijos puedan alcanzar su máximo potencial.
            </p>
            <p>
              Para ello, reforzamos las capacidades en las plataformas
              comunitarias existentes aprovechando la tecnología y fortalecer
              así las capacidades parentales para el desarrollo infantil
              temprano de los niños y niñas
            </p> */}
          </div>
        </div>
      </div>
      <div className="bg-white pt-5 pb-5 border-top-radius border-bottom-radius adelante">
        <div className="container">
          <h6 className="p-mobile-blue letra-gruesa-azul title-h6">
            Trabajamos las 5 dimensiones del modelo Nurturing Care, con énfasis
            en:
          </h6>
          <figure className="p-2 w-100">
            <img src={info1} className=" info-1" alt="info1" />
          </figure>
          <div className="div-items">
            <div className="box-crecer-agua crecer-left">
              <figure className=" box-img-info text-right">
                <img src={crecer} className="w-100 crecer" alt="crecer" />
              </figure>
              <div className="w-65 box-orange">
                Interacciones de calidad para el desarrollo cognitivo y
                socioemocional.{" "}
              </div>
            </div>
            <div className="box-crecer-agua crecer-right">
              <figure className=" box-img-info text-right box-gota-agua">
                <img src={agua} className="w-100 agua" alt="agua" />
              </figure>
              <div className="w-65 box-blue ">
                Higiene y agua segura para el desarrollo físico.{" "}
              </div>
            </div>
          </div>
          <p className="mt-2 estrellita heartbeat">&#10022;</p>
          <div className="container m2-desktop">
            <p className="p-mobile-blue text-info-bottom">
              Mamás, papás, maestras, madres cuidadoras, agentes comunitarias de
              salud y Volar, trabajamos juntos para fortalecer las capacidades
              de crianza que aseguran el desarrollo óptimo de cada niña y niño
              del país.
            </p>
          </div>
        </div>
      </div>
      <div className="timeline-section">
        <div className="timeline-desktop">
          <div className="timeline-container">
            <div className="timeline-info">
              {data?.fases.modular
                .filter((elem) => elem.number !== "4")
                .map((modular) => (
                  <Modular {...modular} />
                ))}
            </div>
            <div className="timeline-row timeline-line">
              <img src={line} className="line-desktop" alt="line" />
            </div>
            <div className="timeline-info timeline-year ">
              {data?.fases.modular
                .filter((elem) => elem.number !== "4")
                .map((modular) => (
                  <div
                    style={{
                      justifySelf: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <p className="timeline-age">{modular.age}</p>
                  </div>
                ))}
            </div>
            <div className="timeline-info">
              <div></div>
              <div></div>
              {/* {modular_content.modular */}
              {/*   .filter((elem) => elem.number === "4") */}
              {/*   .map((modular) => ( */}
              {/*     <Modular {...modular} /> */}
              {/*   ))} */}
              <div></div>
            </div>
          </div>
        </div>
        <div className="timeline-mobile">
          <div className="timeline-container">
            <div className="timeline-info timeline-info-mobile">
              {data?.fases.modular.map((modular) => (
                <Modular {...modular} />
              ))}
            </div>
            <div className="timeline-row timeline-line">
              <img src={lineVerticall} className="line-mobile" alt="line" />
            </div>
            <div className="timeline-info timeline-year ">
              {data?.fases.modular.map((modular) => (
                <div
                  style={{
                    justifySelf: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p className="timeline-age">{modular.age}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img src={cohete} className="timeline-cohete" alt="cohete" />
      </div>
    </div>
  );
};
export default Nosotros;

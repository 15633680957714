import React from 'react'
import cerebro from "../../../../images/modulos-img/cerebro.svg";
import ButtonInfo from "./ButtonInfo.jsx"
import Infografia from "./Infografia"
import VideoInfo from "./VideoInfo"

const Material = ({ material }) => {

  return (
    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4 ">
      <div>
        <figure className="w-20 m-auto">
          <img src={material.fields.icono.fields.file.url} alt="" className="w-100" />
        </figure>
        <h3 className="title-materiales-modulos">{material.fields.tituloMaterial}</h3>
        <p className="subtitle-materiales-modulos">{material.fields.subtitulo}</p>
      </div>
      {material.fields.tipoMateriales.map((tipoMaterial, index) => {

        if (tipoMaterial.fields.soloUnBoton === true) {
          return <ButtonInfo key={index} tipoMaterial={tipoMaterial} />;
        } else if (tipoMaterial.fields.tipo === true) {
          return <Infografia key={index} tipoMaterial={tipoMaterial} />
        } else {
          return <VideoInfo key={index} tipoMaterial={tipoMaterial} />
        }

      })}
    </div>

  )
}

export default Material

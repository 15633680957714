import React from 'react'
import { Button, Modal } from "react-bootstrap";

const VideoInfo = ({ tipoMaterial }) => {
  const [showModal, setShowModal] = React.useState(false)

  const show = () => setShowModal(true)
  const hide = () => setShowModal(false)
  return (
    <>
      <button
        className="btn-modulos-info"
        onClick={() => setShowModal(true)}
      >
        <figure className="btn-infografia">
          {!tipoMaterial.fields.imagenIconoMaterial ? "" : <img src={tipoMaterial.fields.imagenIconoMaterial.fields.file.url} alt="" className="w-100" />}
        </figure>
        <div className="btn-info-text">
          {!tipoMaterial.fields.titulo ? <p>titulo no ingredasdo</p> : tipoMaterial.fields.titulo}
        </div>
      </button>
      {showModal && <Modal show={show} onHide={hide}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="container animated fadeIn">
            <div className="">
              <div className="videoWrapper">
                {!tipoMaterial.fields.urlVideo ? <p>No hay url de video subido</p> :
                  <iframe
                    className="border-video-rutina"
                    width="600"
                    height="350"
                    src={tipoMaterial.fields.urlVideo}
                    frameborder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>}
    </>
  )
}

export default VideoInfo

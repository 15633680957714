import React from 'react'
import { useHistory } from 'react-router-dom'

import './Fase2.css'
import { Router, Route, Link, Switch } from 'react-router-dom'
// import starLeftMobile from "../../../images/fase2-img/star-left-mobile.svg";
// import starRightMobile from "../../../images/fase2-img/star-rigth-mobile.svg";
import cohete from '../../../images/fase2-img/cohete-fase2.svg'
import crecer from '../../../images/nosotros-img/crecer.svg'
import agua from '../../../images/nosotros-img/agua-segura.svg'
import coheteStars from '../../../images/cohete-stars.svg'
import arrowLeft from '../../../images/arrow-left-orange.svg'
import arrowRigth from '../../../images/arrow-right-orange.svg'
import leftArrow from '../../../images/nosotros-img/left-arrow-phases-orange.svg'
import rightArrow from '../../../images/nosotros-img/right-arrow-phases-orange.svg'
import { useNosotros } from '../../../customHooks'
// import smallRocket from "../../../images/nosotros-img/small-rocket.svg"

const Fase2 = () => {
  let history = useHistory()
  const [data, setData] = useNosotros('7zWW2Zr8ivXROS7r97o6Np')
  console.log("data", data);
  return (
    <div className='animated fadeIn'>
      <div className='fase2 border-bottom-radius flex-fase2'>
        {/* <a
          onClick={() => {
            history.goBack();
          }}
        > */}
        <div style={{ width: '5%' }}>
          <a
            href='/nosotros/fase1'
            style={{ display: 'flex', alignContent: 'flex-start' }}
          >
            <img src={leftArrow} />
          </a>
        </div>
        <div>
          <h6 className='titulo-numero-fase2'>Fase 2</h6>
          <h5 className='titulo-fase2  tracking-in-expand-fwd-top'>{data?.fase2.fields.nombreDeLaFase}</h5>
          <p className='p-mobile-white w-fase'>{data?.fase2.fields.textoIntroductorio}</p>
        </div>
        <div style={{ width: '5%' }}>
          <a
            href='/nosotros/fase3'
            style={{ display: 'flex', alignContent: 'flex-end' }}
          >
            <img src={rightArrow} />
          </a>
        </div>
      </div>
      <div className='box-botones-fase2'>
        <a
          href='/nosotros/fase2/crecer'
          className='btn-fase2 font-color-orange wobble-hor-bottom'
        >
          <img src={crecer} className='w-12 mr-2 '></img>
          Crecer mejor
        </a>
        <a
          href='/nosotros/fase2/agua-segura'
          className='btn-fase2 text-aguamarina wobble-hor-bottom'
        >
          <img src={agua} className='w-10 mr-2'></img>
          Agua segura
        </a>
        <a
          href='/nosotros/fase2/el-piloto'
          className='btn-fase-piloto wobble-hor-bottom'
        >
          <img src={cohete} className='w-12 mr-2'></img>
          El piloto
        </a>
      </div>
      <figure className='w-100'>
        <img src={coheteStars} className='cohete-fase2'></img>
      </figure>

      <div className='next-phase-background' style={{ marginTop: '20px' }}>
        <a
          href={data?.memoria2018.fields.file.url}
          target='_blank'
          className='next-phase-btn wobble-hor-bottom'
          download
        >
          <img src={arrowLeft} className='arrow-icon' alt='arrowLeft' />{' '}
          <p
            className='next-phase-btn-text'
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 2px',
              fontSize: '1.2rem',
            }}
          >
            Descargar Memoria
          </p>
          <img src={arrowRigth} className='arrow-icon' alt='arrowRight' />
        </a>
      </div>
    </div>
  )
}

export default Fase2

import React from "react";
import "./../../cuidador/areas/areas.css";

import { useHistory } from "react-router-dom";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import iconoSesamoWhite from "./../../../../images/areas-img/icon_sesamo.png";
import focoRosado from "./../../../../images/docente/foco-rosado.svg";
import { useCollection } from "react-firebase-hooks/firestore";
import { auth, db } from "../../../firebase/firebase";
import { Orbitals } from "react-spinners-css";
import arrTips from "../../../../data";
import { useMaterialesEducativos } from '../../../../customHooks';

const ListMaterialEducativo = ({
  contenidoFirebase,
  error,
  loading,
  firebaseUser,
  idChild,
}) => {
  let history = useHistory();
  const [data, setData] = useMaterialesEducativos('materialesEducativos')
  let arraySesamo = arrTips;

  arraySesamo.sort(function(a, b) {
    if (a.n_tip > b.n_tip) {
      return 1;
    }
    if (a.n_tip < b.n_tip) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });

  const mandarNumberTipOficial = (numberTip) => {
    localStorage.setItem("localNumberTip", numberTip);
  };

  return (
    <div>
      <>
        <div>
          <div className="box-title-listMaterialEducativo show-desktop">
            <h1 className="align-center text-blue">¡Bienvenido!</h1>
            <h1 className="align-center text-blue bold">
              Conoce los recursos de nuestros socios
            </h1>
          </div>
          <div className="list-material-educativo">
            <a
              onClick={() => {
                history.goBack();
              }}
            >
              <i
                className="fas fa-chevron-left arrowGoback goBack-area"
                aria-hidden="true"
              ></i>
            </a>
            <div className="container-fluid">
              <div className="row">
                {data && data
                  .map((item, index) =>
                    item.fields.tipo === "VIDEO EDUCATIVO" ||
                      item.fields.tipo === "CANCIÓN" ? (
                      <div
                        key={index}
                        className="col-sm-12 col-md-12 col-lg-4 col-xl-4"
                      >
                        <Link
                          className="link"
                          to={{
                            pathname: '/aprendamos/aliado/docente/material-educativo/individual',
                            state: { data: item }
                          }}
                          onClick={() => mandarNumberTipOficial(index)}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className="box-section box-section-material-educativo">
                            <img
                              src={focoRosado}
                              className="icono-video-tip"
                              alt="icono de tip"
                            />
                            <div className="box-text-material-educativo">
                              <h3>{item.fields.titulo}</h3>
                              <h5 className="text-video-tip">
                                N° {index + 1} {item.fields.tipo}
                              </h5>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ) : (
                      <div
                        key={item.sys.id}
                        className="col-sm-12 col-md-12 col-lg-4 col-xl-4"
                      >
                        <a className="link" href={item.fields.urlVideo} target="_blank">
                          <div className="box-section box-section-material-educativo">
                            <img
                              src={focoRosado}
                              className="icono-video-tip"
                              alt="icono de tip"
                            />
                            <div className="box-text-material-educativo">
                              <h3>{item.fields.titulo}</h3>
                              <h5 className="text-video-tip">
                                N° {index + 1} {item.fields.tipo}
                              </h5>
                            </div>
                          </div>
                        </a>
                      </div>
                    )
                  )}
              </div>
            </div>
          </div>
        </div>
      </>
    </div >
  );
};

export default ListMaterialEducativo;

import React from 'react'
import iconoTipLighBlue from "./../../../../images/icon-tip-ligh-blue.svg";

const ModuloTemplate = ({ data, i, setIndexSelection, handleShowModulo }) => {

  const handleOnClick = () => {
    handleShowModulo();
    setIndexSelection(i)

  }

  return (
    <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
      <btn className="link" onClick={() => handleOnClick()}>
        <div className="box-section box-section-higiene">
          <div class="box-icon">
            <img
              src={iconoTipLighBlue}
              className="icono-modulo-tip heartbeat"
              alt="icono de tip"
            />
          </div>
          <div className="box-text-modulo">
            <h3>{data.fields.titulo}</h3>
          </div>
        </div>
      </btn>
    </div>
  )

}

export default ModuloTemplate

import React from 'react'
import "./Redes.css"
import { useHomePageHook } from "../../customHooks"

const Redes = () => {
  const [homePage] = useHomePageHook()
  return (
    <div className="cont-redes">
      <a href={homePage.urlFacebook} target="_blank"><i className="fab fa-facebook-f"></i></a>
      <a href={homePage.urlInstagram} target="_blank"><i className="fab fa-instagram" ></i></a>
      <a href={homePage.urlYoutubeCanal} target="_blank"><i className="fab fa-youtube "></i></a>
    </div>

  )
}

export default Redes

import React from "react";

export const SliderItem = ({ image, text }) => {
  return (
    <div className="slider-item">
      <div>
        <img src={require(`../../../images/covid19/${image}`)} alt='image' />
      </div>
      {/*  <p className="slider-item-text">{text}</p> */}
      <p
        className="slider-item-text"
        dangerouslySetInnerHTML={{ __html: text }}
      ></p>
    </div>
  );
};

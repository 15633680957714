import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'

import sliders_content from '../../../content/slider.json'
import components_content from '../../../content/components.json'
import { Component } from './Component'
import { Slider } from './Slider'
import { Slider2 } from './Slider2'
import { InfoItem } from './InfoItem'
import { Item } from './Item'
import './covid19.css'

import starsLeftDesktop from '../../../images/stars-white-left.png'
import starsRightDesktop from '../../../images/stars-white-right.png'
import starsLeftMobile from '../../../images/covid19/stars-white-mobile-left.svg'
import starsRightMobile from '../../../images/covid19/stars-white-mobile-right.svg'

import Asset1 from '../../../images/covid19/asset1.png'
import Asset2 from '../../../images/covid19/asset2.png'
import Asset3 from '../../../images/covid19/asset3.png'

import coheteLuna from '../../../images/cohete-stars.svg'
import logo from '../../../images/menu-img/logo-volar.svg'
import vector from '../../../images/nosotros-img/vector.svg'
import smsIcon from '../../../images/nosotros-img/sms-icon2.svg'
import workshopsIcon from '../../../images/nosotros-img/workshops-icon.svg'
import resourcesIcon from '../../../images/nosotros-img/resources-icon.svg'
import meetingsIcon from '../../../images/nosotros-img/meetings-icon.svg'
import connectivityIcon from '../../../images/nosotros-img/internet-icon.svg'
import videocallIcon from '../../../images/nosotros-img/video-call-icon.svg'
import volunteeringIcon from '../../../images/covid19/volunteering-icon.svg'
import contentIcon from '../../../images/covid19/content-icon.svg'
import teacherIcon from '../../../images/covid19/teacher-icon.svg'
import antennagIcon from '../../../images/covid19/antennag-icon.png'
import arrowLeft from '../../../images/covid19/arrow-left-gray.svg'

import arrowRight from '../../../images/covid19/arrow-right-gray.svg'

import arrowLeftt from '../../../images/arrow-left-orange.svg'
import arrowRigth from '../../../images/arrow-right-orange.svg'

import internetIcon from '../../../images/nosotros-img/internet-icon.svg'
import maxWorkshops from '../../../images/nosotros-img/maxWorkshops-icon.svg'

import sliderMinsurfa from '../../../images/covid19/minsur-families_slider.svg'
import sliderMinsurdoc from '../../../images/covid19/minsur-teachers_slider.svg'
import sliderTasa from '../../../images/covid19/tasa_slider.svg'
import sliderRimac from '../../../images/covid19/rimac_slider.svg'
import sliderCentria from '../../../images/covid19/centria_slider.svg'

import sliderCohete1 from '../../../images/covid19/slider-cohete1.svg'
import arrowLeftSlider from '../../../images/covid19/arrow-left-slider.svg'
import arrowRightSlider from '../../../images/covid19/arrow-right-slider.svg'

import maxSmsIcon from '../../../images/nosotros-img/maxSms-icon.svg'
import maxWorkshopsIcon from '../../../images/nosotros-img/maxWorkshops-icon.svg'
import maxResourcesIcon from '../../../images/nosotros-img/maxResources-icon.svg'
import maxMeetingsIcon from '../../../images/nosotros-img/maxMeetings-icon.svg'

import leftArrow from '../../../images/nosotros-img/left-arrow-phases.svg'
import rightArrow from '../../../images/nosotros-img/right-arrow-phases.svg'
import smallRocket from '../../../images/nosotros-img/small-rocket.svg'

const Covid = (props) => {
  const [current_data, setCurrent_data] = useState(1)
  const [current_slider, setCurrent_slider] = useState(1)
  const [show, setShow] = useState(false)

  const prevSlider = (value, setValue) => {
    if (value !== 1) {
      setValue(value - 2)
    }
  }

  const nextSlider = (value, setValue, max) => {
    if (value !== max) {
      setValue(value + 2)
    } else {
      setValue(1)
    }
  }

  function handleChange(newdata) {
    setCurrent_data(newdata)
  }

  const handleClose = () => setShow(!show)
  const handleShow = () => setShow(!show)

  var Slidermod

  if (current_data === 1) {
    Slidermod = (
      <div>
        {sliders_content.data.map((slider) => (
          <Slider
            key={slider.key}
            {...slider}
            show={current_data === slider.key}
          />
        ))}
      </div>
    )
  } else {
    Slidermod = (
      <div>
        {sliders_content.data.map((slider) => (
          <Slider2
            key={slider.key}
            {...slider}
            show={current_data === slider.key}
          />
        ))}
      </div>
    )
  }

  return (
    <div id='nosotros-covid'>
      <div
        id='tittle-covid'
        className='box-title-covid animated fadeIn mt-3 flex-covid'
      >
        {/* <div style={{ width: "5%" }}> */}
        {/*   <br /> */}
        {/*   <br /> */}
        {/*   <a href="/nosotros/fase3" style={{ display: "flex", alignContent: "flex-start" }}> */}
        {/*     <img className="arrowCovidLeft" src={leftArrow} /> */}
        {/*   </a> */}
        {/* </div> */}
        {/*<div>
          <img
            src={starsLeftDesktop}
            className="stars heartbeat"
            alt="stars-left"
          />
          <img
            src={starsRightMobile}
            className="stars-mobile heartbeat"
            alt="stars-left"
          />
        </div>*/}
        <div className='title-container'>
          <br />
          <div>
            <h1 className='title-covid '>Volar frente a la emergencia</h1>
            {/* <p className="main-text "> */}
            <p className='p-mobile-white '>
              La emergencia sanitaria por el covid-19 supuso para Volar, pero
              sobre todo para las familias con hijas e hijos pequeños, nuevos
              retos en la crianza y la educación. Es por ello que en el 2020,
              Volar redefine los componentes de su modelo de intervención para
              ajustarse a las nuevas necesidades identificadas.
            </p>
          </div>
        </div>

        {/*<div>
          <img
            src={starsRightDesktop}
            className="stars heartbeat"
            alt="stars-right"
          />
          <img
            src={starsLeftMobile}
            className="stars-mobile heartbeat"
            alt="stars-right"
          />
        </div>*/}

        {/* <div style={{ width: "5%" }}> */}
        {/*   <br /> */}
        {/*   <br /> */}
        {/*   <a href="/nosotros/fase4" style={{ display: "flex", justifyContent: "flex-end" }}> */}
        {/*     <img className="arrowCovidRight" src={rightArrow} /> */}
        {/*   </a> */}
        {/* </div> */}
      </div>
      <div className='banner-section' style={{ marginTop: '5px' }}>
        <p
          className='nosotros-covid-title'
          style={{ color: '#2E335E', fontWeight: 'bold' }}
        >
          ¿Como nos adaptamos a la coyuntura?
        </p>
        <div className='hidde-asset'>
          <div className='row' style={{ marginTop: '50px' }}>
            <div className='column' style={{ flex: '0 0 368px' }}>
              <div className='row '>
                <p
                  className='banner-text2 '
                  style={{
                    color: '#7DC7C5',
                    marginTop: '0px',
                    marginRight: '100px',
                  }}
                >
                  1.
                </p>
                <p
                  className='banner-text '
                  style={{ marginBottom: '-50px', width: '300px' }}
                >
                  {' '}
                  <mark style={{ color: '#7DC7C5', background: 'none' }}>
                    Digitalizacion{' '}
                  </mark>{' '}
                  de la totalidad de contenidos para llegar a los hogares de
                  manera remota
                </p>
              </div>
            </div>
            <div className='column' style={{ flex: '0 0 368px' }}></div>
            <div className='column' style={{ flex: '0 0 368px' }}>
              <div className='row '>
                <p
                  className='banner-text2'
                  style={{
                    color: '#A78BD2',
                    marginTop: '0px',
                    marginRight: '10px',
                  }}
                >
                  3.
                </p>
                <p
                  className='banner-text '
                  style={{
                    marginBottom: '-50px',
                    width: '300px',
                    marginTop: '70px',
                  }}
                >
                  Desarrollo de contenidos para el apoyo a la educación en casa:
                  <mark style={{ color: '#A78BD2', background: 'none' }}>
                    ampliación del alcance a niños , niñas y jovenes entre 5 a
                    17 años
                  </mark>{' '}
                </p>
              </div>
            </div>
          </div>
          <div className='row' style={{}}>
            <div className='column' style={{ flex: '0 0 368px' }}></div>
            <div
              className='column'
              style={{ flex: '0 0 368px', alignItems: 'center' }}
            >
              <img className='asset' src={Asset2} alt={'asset2'} />
            </div>
            <div className='column' style={{ flex: '0 0 368px' }}></div>
          </div>

          <div className='row' style={{}}>
            <div
              className='column'
              style={{ alignItems: 'flex-end', flex: '0 0 368px' }}
            >
              <img className='asset' src={Asset1} alt={'asset1'} />
            </div>
            <div className='column' style={{ flex: '0 0 368px' }}></div>
            <div
              className='column'
              style={{ alignItems: 'flex-start', flex: '0 0 368px' }}
            >
              <img className='asset' src={Asset3} alt={'asset3'} />
            </div>
          </div>
          <div className='row' style={{}}>
            <div className='column' style={{ flex: '0 0 368px' }}></div>
            <div className='column' style={{ flex: '0 0 350px' }}>
              <div className='row '>
                <p
                  className='banner-text2'
                  style={{
                    color: '#EF8B44',
                    marginTop: '0px',
                    marginRight: '10px',
                  }}
                >
                  2.
                </p>
                <p
                  className='banner-text'
                  style={{ marginTop: '-50px', width: '300px' }}
                >
                  Fortalecimiento de{' '}
                  <mark style={{ color: '#EF8B44', background: 'none' }}>
                    contenidos para tratar prevención COVID
                  </mark>{' '}
                  y manejo de la emergencia sanitaria
                </p>
              </div>
            </div>
            <div className='column' style={{ flex: '0 0 368px' }}></div>
          </div>
        </div>

        <div className='component-hidden2'>
          <div className='row' style={{ marginTop: '50px' }}>
            <div className='column' style={{ flex: '0 0 225px' }}>
              <div className='row'>
                <p
                  className='banner-text2'
                  style={{ color: '#7DC7C5', marginBottom: '62px' }}
                >
                  1.
                </p>
                <p
                  className='banner-text banner-text3'
                  style={{ marginBottom: '0px', width: '180px' }}
                >
                  {' '}
                  <mark style={{ color: '#7DC7C5', background: 'none' }}>
                    Digitalizacion{' '}
                  </mark>{' '}
                  de la totalidad de contenidos para llegar a los hogares de
                  manera remota
                </p>
              </div>
            </div>
            <div className='column' style={{ flex: '0 0 225px' }}></div>
            <div className='column' style={{ flex: '0 0 225px' }}>
              <div className='row'>
                <p
                  className='banner-text2'
                  style={{ color: '#A78BD2', marginBottom: '55px' }}
                >
                  3.
                </p>
                <p
                  className='banner-text banner-text3'
                  style={{
                    width: '180px',
                    marginTop: '0px',
                    marginBottom: '-50px',
                  }}
                >
                  Desarrollo de contenidos para el apoyo a la educación en casa:
                  <mark style={{ color: '#A78BD2', background: 'none' }}>
                    ampliación del alcance a niños , niñas y jovenes entre 5 a
                    17 años
                  </mark>{' '}
                </p>
              </div>
            </div>
          </div>
          <div className='row' style={{}}>
            <div className='column' style={{ flex: '0 0 225px' }}></div>
            <div
              className='column'
              style={{ alignItems: 'center', flex: '0 0 225px' }}
            >
              <img className='asset' src={Asset2} alt={'asset2'} />
            </div>
            <div className='column' style={{ flex: '0 0 225px' }}></div>
          </div>

          <div className='row' style={{}}>
            <div
              className='column'
              style={{ alignItems: 'flex-end', flex: '0 0 225px' }}
            >
              <img className='asset' src={Asset1} alt={'asset1'} />
            </div>
            <div className='column' style={{ flex: '0 0 225px' }}></div>
            <div
              className='column'
              style={{ alignItems: 'flex-start', flex: '0 0 225px' }}
            >
              <img className='asset' src={Asset3} alt={'asset3'} />
            </div>
          </div>
          <div className='row'>
            <div className='column' style={{ flex: '0 0 225px' }}></div>
            <div className='column' style={{ flex: '0 0 225px' }}>
              <div className='row'>
                <p
                  className='banner-text2'
                  style={{
                    color: '#EF8B44',
                    marginTop: '2px',
                    marginBottom: '212px',
                  }}
                >
                  2.
                </p>
                <p
                  className='banner-text banner-text3'
                  style={{ marginTop: '-50px', width: '170px' }}
                >
                  Fortalecimiento de{' '}
                  <mark style={{ color: '#EF8B44', background: 'none' }}>
                    contenidos para tratar prevención COVID
                  </mark>{' '}
                  y manejo de la emergencia sanitaria
                </p>
              </div>
            </div>
            <div className='column' style={{ flex: '0 0 225px' }}></div>
          </div>
        </div>

        <div className='display-component component-hidde'>
          <div className='coyuntura'>
            <p
              className='banner-text2'
              style={{
                color: '#7DC7C5',
                marginTop: '0px',
                marginRight: '10px',
              }}
            >
              1.
            </p>
            <p className='banner-text' style={{ width: '250px' }}>
              {' '}
              <mark style={{ color: '#7DC7C5', background: 'none' }}>
                Digitalizacion{' '}
              </mark>{' '}
              de la totalidad de contenidos para llegar a los hogares de manera
              remota
            </p>
          </div>
          <div className='coyuntura'>
            <p
              className='banner-text2'
              style={{
                color: '#EF8B44',
                marginTop: '0px',
                marginRight: '10px',
              }}
            >
              2.
            </p>
            <p className='banner-text' style={{ width: '250px' }}>
              Fortalecimiento de{' '}
              <mark style={{ color: '#EF8B44', background: 'none' }}>
                contenidos para tratar prevención COVID
              </mark>{' '}
              y manejo de la emergencia sanitaria
            </p>
          </div>
          <div className='coyuntura'>
            <p
              className='banner-text2'
              style={{
                color: '#A78BD2',
                marginTop: '0px',
                marginRight: '10px',
              }}
            >
              3.
            </p>
            <p className='banner-text' style={{ width: '250px' }}>
              Desarrollo de contenidos para el apoyo a la educación en casa:
              <mark style={{ color: '#A78BD2', background: 'none' }}>
                ampliación del alcance a niños , niñas y jovenes entre 5 a 17
                años
              </mark>{' '}
            </p>
          </div>
        </div>

        <p
          className='section-title nosotros-covid-title'
          style={{ color: '#2E335E', fontWeight: 'bold', marginTop: '30px' }}
        >
          Modelos de intervención
        </p>
        <div className='banner-section'>
          <div className='component-hidden'>
            <div className='info-container '>
              <div className='info-item info-title'>
                <div style={{ width: 'fit-content', textAlign: 'left' }}>
                  <p className='info-main-text'>Variante </p>
                  <p className='info-plus-text'>
                    + Comunidades <br /> BRECA
                  </p>
                </div>
              </div>
              <div className='info-item info-graphics'>
                <div style={{ display: 'flex', gap: '15px' }}>
                  <p className='info-number'>1.</p>
                  <div>
                    <img src={logo} className='info-logo' alt='logo' />
                    <p className='info-text' style={{ color: '#CCCCFF' }}>
                      emergencia
                    </p>
                    <p className='info-text' style={{ color: '#FFC000' }}>
                      Docentes y directivos
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <InfoItem
                    icon={smsIcon}
                    text='SMS según nivel de enseñanza'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={workshopsIcon}
                    text='Formación docente'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={resourcesIcon}
                    text='Videos y recursos educativos'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={connectivityIcon}
                    text='Tablets y megas para acceso a internet y recursos'
                    color='#A78BD2'
                  />
                </div>
              </div>
              <div className='info-item info-graphics'>
                <div style={{ display: 'flex', gap: '15px' }}>
                  <p className='info-number'>2.</p>
                  <div>
                    <img src={logo} className='info-logo' alt='logo' />
                    <p className='info-text' style={{ color: '#CCCCFF' }}>
                      emergencia
                    </p>
                    <p className='info-text' style={{ color: '#FFC000' }}>
                      Hogares
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <InfoItem
                    icon={smsIcon}
                    text='SMS según edad del hijo/a'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={resourcesIcon}
                    text='Videos y recursos de apoyo educativo y crianza'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={meetingsIcon}
                    text='Talleres virtuales'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={connectivityIcon}
                    text='Tablets y megas para acceso a internet y recursos'
                    color='#A78BD2'
                  />
                </div>
              </div>
            </div>
          </div>

          <div className='component-hidde'>
            <div className=''>
              <div className='info-item info-title'>
                <div style={{ width: 'fit-content', textAlign: 'left' }}>
                  <p className='info-main-text'>Variante </p>
                  <p className='info-plus-text'>
                    + Comunidades <br /> BRECA
                  </p>
                </div>
              </div>
              <div className='info-item info-graphics'>
                <div style={{ display: 'flex', gap: '15px' }}>
                  <p className='info-number'>1.</p>
                  <div>
                    <img src={logo} className='info-logo' alt='logo' />
                    <p className='info-text' style={{ color: '#CCCCFF' }}>
                      emergencia
                    </p>
                    <p className='info-text' style={{ color: '#FFC000' }}>
                      Docentes y directivos
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <InfoItem
                    icon={smsIcon}
                    text='SMS según nivel de enseñanza'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={workshopsIcon}
                    text='Formación docente'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={resourcesIcon}
                    text='Videos y recursos educativos'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={connectivityIcon}
                    text='Tablets y megas para acceso a internet y recursos'
                    color='#A78BD2'
                  />
                </div>
              </div>
              <div className='info-item info-graphics'>
                <div style={{ display: 'flex', gap: '15px' }}>
                  <p className='info-number'>2.</p>
                  <div>
                    <img src={logo} className='info-logo' alt='logo' />
                    <p className='info-text' style={{ color: '#CCCCFF' }}>
                      emergencia
                    </p>
                    <p className='info-text' style={{ color: '#FFC000' }}>
                      Hogares
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <InfoItem
                    icon={smsIcon}
                    text='SMS según edad del hijo/a'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={resourcesIcon}
                    text='Videos y recursos de apoyo educativo y crianza'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={meetingsIcon}
                    text='Talleres virtuales'
                    color='#47CCCD'
                  />
                  <InfoItem
                    icon={connectivityIcon}
                    text='Tablets y megas para acceso a internet y recursos'
                    color='#A78BD2'
                  />
                </div>
              </div>
            </div>
          </div>

          <br />
          <br />
          <br />
          <img
            src={vector}
            alt='vector'
            style={{ width: '100%', margin: 'auto' }}
          />
          <br />
          <br />
          <br />
          <div className='component-hidden'>
            <div className='info-container'>
              <div className='info-item info-title'>
                <div style={{ width: 'fit-content', textAlign: 'left' }}>
                  <p className='info-main-text'>Variante </p>
                  <p className='info-plus-text2'>
                    + Colaboradores
                    <br /> BRECA
                  </p>
                </div>
              </div>
              <div className='info-item info-graphics'>
                <InfoItem
                  icon={smsIcon}
                  text='SMS según nivel de enseñanza'
                  color='#47CCCD'
                />
                <br />
                <InfoItem
                  icon={meetingsIcon}
                  text='Talleres virtuales'
                  color='#47CCCD'
                />
              </div>
              <div className='info-item info-graphics'>
                <InfoItem
                  icon={resourcesIcon}
                  text='Videos y recursos educativos'
                  color='#47CCCD'
                />
                <br />
                <InfoItem
                  icon={videocallIcon}
                  text='Voluntariado Corporativo'
                  color='#A78BD2'
                />
              </div>
            </div>
          </div>

          <div className='component-hidde'>
            <div className=''>
              <div className='info-item info-title'>
                <div style={{ width: 'fit-content', textAlign: 'left' }}>
                  <p className='info-main-text'>Variante </p>
                  <p className='info-plus-text2'>
                    + Colaboradores
                    <br /> BRECA
                  </p>
                </div>
              </div>
              <div className='info-item info-graphics'>
                <InfoItem
                  icon={smsIcon}
                  text='SMS según nivel de enseñanza'
                  color='#47CCCD'
                />
                <InfoItem
                  icon={meetingsIcon}
                  text='Talleres virtuales'
                  color='#47CCCD'
                />
                <InfoItem
                  icon={resourcesIcon}
                  text='Videos y recursos educativos'
                  color='#47CCCD'
                />
                <InfoItem
                  icon={videocallIcon}
                  text='Voluntariado Corporativo'
                  color='#A78BD2'
                />
              </div>
            </div>
          </div>
          {/*<p className="nosotros-covid-title" style={{ color: "#7DC7C5" }}>
          Resultados
        </p>
        <br />
        <p className="nosotros-covid-text">
          En el caso de{" "}
          <strong style={{ color: "#4CD3D3" }}>
            Volar frente a la emergencia- Colaboradores
          </strong>{" "}
          BRECA logramos:
        </p>
        <br />
        <div className="results-container">
          <div className="results-item nosotros-covid-text results-blue">
            Reducción de la preocupación de los colaboradores por la educación
            de sus hijos/as
          </div>
          <div className="results-item nosotros-covid-text results-blue">
            Mejora en el acceso a plataformas educativas especializadas
          </div>
          <div className="results-item nosotros-covid-text results-blue">
            Reducción de la incidencia de problemas de salud mental en
            colaboradores con hijos/as menores edad
          </div>
          <div className="results-item nosotros-covid-text results-blue">
            Mejora en hábitos de estudio adecuados
          </div>
          <div className="results-item nosotros-covid-text results-blue">
            Mejora en sus habilidades para crianza y educación
          </div>
          <div className="results-item nosotros-covid-text results-blue">
            Mejora en el desempeño en matemáticas y comunicación
          </div>
          <div className="results-item nosotros-covid-text results-blue">
            Mejoras en la adaptación a la educación a distancia
          </div>
          <div className="results-item nosotros-covid-text results-blue">
            Mejora la organización del espacio de estudio
          </div>
        </div>*/}
        </div>

        {/*<div className="new-section-row " style={{ marginBottom: "10px", marginTop: "100px" }}>
          <div className="new-section-container">
            <div className="new-section-item info-title">
              <div style={{ width: "fit-content", textAlign: "center" }}>
                <p className="info-plus-text">+ Comunidades <br /> BRECA</p>
              </div>
            </div>
          </div>
          <div className="new-section-container">
            <div className="new-section-item info-title">
              <div style={{ width: "fit-content", textAlign: "center" }}>
                <p className="info-plus-text2 component-hidden">+ Colaboradores <br /> BRECA</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row2 component-hidden" style={{ marginBottom: "150px" }}>
          <div className="column" style={{ borderRight: "2px dashed #ef8b44" }}>
            <div className="row" style={{ justifyContent: "flex-start" }}>
              <p className="models-text">Docentes y directivos</p>
            </div>
            <div className="row2" style={{ marginBottom: "20px" }}>
              <img src={smsIcon} className="images-size"></img>
              <img src={workshopsIcon} className="images-size"></img>
              <img src={resourcesIcon} className="images-size"></img>
              <img src={internetIcon} className="images-size"></img>
            </div>
            <div className="row" style={{ justifyContent: "flex-start" }}>
              <p className="models-text">Hogares</p>
            </div>
            <div className="row2">
              <img src={smsIcon} className="images-size"></img>
              <img src={resourcesIcon} className="images-size"></img>
              <img src={maxWorkshops} className="images-size"></img>
              <img src={internetIcon} className="images-size"></img>
            </div>
          </div>
          <div className="column">
            <div className="row component-hidden" style={{ justifyContent: "flex-start" }}>
              <p className="models-text" style={{ color: "#FFFF" }}>RELLENO</p>
            </div>
            <div className="row2">
              <img src={smsIcon} className="images-size"></img>
              <img src={resourcesIcon} className="images-size"></img>
              <img src={maxWorkshops} className="images-size"></img>
              <img src={internetIcon} className="images-size"></img>
            </div>
            <div className="row2"></div>
            <div className="row2"></div>
          </div>
        </div>

        <div className="display-component component-hidde">
          <div className="row" style={{ justifyContent: "center" }}>
              <p className="models-text">Docentes y directivos</p>
            </div>
            <div className="row2" style={{ marginBottom: "20px", justifyContent: "center"}}>
              <img src={smsIcon} className="images-size"></img>
              <img src={workshopsIcon} className="images-size"></img>
              <img src={resourcesIcon} className="images-size"></img>
              <img src={internetIcon} className="images-size"></img>
            </div>
            <div className="row" style={{ justifyContent: "center" }}>
              <p className="models-text">Hogares</p>
            </div>
            <div className="row2" style={{ marginBottom: "40px", justifyContent: "center"}}>
              <img src={smsIcon} className="images-size"></img>
              <img src={resourcesIcon} className="images-size"></img>
              <img src={maxWorkshops} className="images-size"></img>
              <img src={internetIcon} className="images-size"></img>
            </div>

            <div className="new-section-container">
            <div className="new-section-item info-title">
              <div style={{ width: "fit-content", textAlign: "center" }}>
                <p className="info-plus-text2">+ Colaboradores <br /> BRECA</p>
              </div>
            </div>
            </div>

            <div className="row2" style={{ marginBottom: "20px", justifyContent: "center"}}>
              <img src={smsIcon} className="images-size"></img>
              <img src={resourcesIcon} className="images-size"></img>
              <img src={maxWorkshops} className="images-size"></img>
              <img src={internetIcon} className="images-size"></img>
            </div>

        </div>*/}

        <p
          className='section-title nosotros-covid-title'
          style={{ color: '#2E335E', fontWeight: 'bold', marginTop: '30px' }}
        >
          Componentes
        </p>
        <div className='slider-container'>
          <div>
            <img
              className='arrow-slider'
              src={arrowLeftSlider}
              alt='arrowLeftSlider'
              onClick={() => prevSlider(current_slider, setCurrent_slider)}
            />
          </div>
          <div className='components-container' style={{ width: '90%' }}>
            <div>
              {components_content.data.map((slider) => (
                <Component
                  key={slider.key}
                  {...slider}
                  show={current_slider === slider.key}
                />
              ))}
            </div>
            <div className='component-hidden'>
              {components_content.data.map((slider) => (
                <Component
                  key={slider.key}
                  {...slider}
                  show={current_slider + 1 === slider.key}
                />
              ))}
            </div>
            {/*<Component
              title="Voluntariado corporativo"
              text="Sesiones de acompañamiento educativo para el refuerzo de aprendizajes y desarrollo de competencias en niñas y niños en educación básica regular."
              icon={volunteeringIcon}
            />
            <Component
              title="Talleres Virtuales a familias"
              text="Sesiones pre-grabadas y talleres virtuales en vivo para reforzar el acompañamiento educativo que se da desde casa y potenciar las estrategias de crianza que manejan las familias"
              icon={resourcesIcon}
            />*/}
          </div>
          <div>
            <img
              className='arrow-slider'
              src={arrowRightSlider}
              alt='arrowRightSlider'
              onClick={() =>
                nextSlider(
                  current_slider,
                  setCurrent_slider,
                  sliders_content.data.length
                )
              }
            />
          </div>
        </div>
        {/*
        <p className="section-title nosotros-covid-title" style={{ color: "#2E335E", fontWeight: "bold" }}>Componentes</p>
        <div className="components-container" style={{ marginBottom: "300px" }}>
          <Component
            title="Voluntariado corporativo"
            text="Sesiones de acompañamiento educativo para el refuerzo de aprendizajes y desarrollo de competencias en niñas y niños en educación básica regular."
            icon={volunteeringIcon}
          />
          <Component
            title="Talleres Virtuales a familias"
            text="Sesiones pre-grabadas y talleres virtuales en vivo para reforzar el acompañamiento educativo que se da desde casa y potenciar las estrategias de crianza que manejan las familias"
            icon={resourcesIcon}
          />
          <Component
            title="Conectividad Volar"
            text="Entrega de tablets y paquetes de datos móviles a familias en situación de vulnerabilidad para garantizar su acceso a la educación a distancia y a los talleres virtuales del proyecto"
            icon={antennagIcon}
          />
          <Component
            title="SMS Volar"
            text="Según la edad de las niñas y niños en las familias, se envían mensajes de texto de manera frecuente que brindan consejos para la crianza, la nutrición e higiene, el soporte emocional y el acompañamiento educativo."
            icon={smsIcon}
          />
          <Component
            title="Contenido Volar"
            text="Distribución de recursos y contenidos audiovisuales como videos tips, pastillas educativas, infografías y talleres pre-grabados para fortalecer hábitos de crianza, el apoyo en la educación y el desarrollo de vínculos en las familias."
            icon={contentIcon}
          />
          <Component
            title="Formación docente"
            text="En alianza con la ONG Enseña Perú, se desarrollan talleres con tres ejes de formación para docentes en Puno, estos son Educación Basada en Competencias, Evaluación Formativa y Habilidades Socioemocionales"
            icon={teacherIcon}
          />
        </div>*/}

        <p
          className='section-title nosotros-covid-title'
          style={{ color: '#2E335E', fontWeight: 'bold', marginTop: '60px' }}
        >
          Conoce los resultados obtenidos con cada unidad de <br />
          negocio durante el 2020
        </p>
        <div className='component-hidden'>
          <div
            className='row'
            style={{ justifyContent: 'space-evenly', padding: '5rem 0 5rem 0' }}
          >
            <div
              className='slider-img'
              onClick={() => {
                handleChange(1)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderMinsurfa} />
              </div>
            </div>

            {/* Eliminar este */}
            {/* <div className=" slider-img" onClick={() => {handleChange(2);handleShow();}}>
                <div className="blank-box-color">
                  <img className="image-show" src={sliderMinsurdoc}/>
                </div>
              </div> */}
            {/* Eliminar este */}

            <div
              className='slider-img'
              onClick={() => {
                handleChange(3)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderTasa} />
              </div>
            </div>
          </div>
          <div className='row' style={{ justifyContent: 'space-evenly' }}>
            <div
              className='slider-img'
              onClick={() => {
                handleChange(4)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderRimac} />
              </div>
            </div>
            <div
              className='slider-img'
              onClick={() => {
                handleChange(5)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderCentria} />
              </div>
            </div>
            {/* <div className="blank-box-white"/> */}
          </div>
        </div>

        <div className='display-component component-hidde'>
          <div className='column'>
            <div
              className='slider-img'
              onClick={() => {
                handleChange(1)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderMinsurfa} />
              </div>
            </div>

            {/* Eliminar este */}
            {/* <div className=" slider-img" onClick={() => {handleChange(2);handleShow();}}>
          <div className="blank-box-color">
            <img className="image-show" src={sliderMinsurdoc}/>
            </div>
          </div> */}
            {/* Eliminar este */}

            <div
              className=' slider-img'
              onClick={() => {
                handleChange(3)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderTasa} />
              </div>
            </div>
            <div
              className=' slider-img'
              onClick={() => {
                handleChange(4)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderRimac} />
              </div>
            </div>
            <div
              className=' slider-img'
              onClick={() => {
                handleChange(5)
                handleShow()
              }}
            >
              <div className='blank-box-color'>
                <img className='image-show' src={sliderCentria} />
              </div>
            </div>
          </div>
        </div>
        <Modal
          scrollable={true}
          dialogClassName='my-modal'
          show={show}
          onHide={handleClose}
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            {current_data == 1 ? (
              <div>
                {sliders_content.data.map((slider) => (
                  <Slider2
                    key={slider.key}
                    {...slider}
                    show={current_data === slider.key}
                  />
                ))}
              </div>
            ) : (
              <div>
                {sliders_content.data.map((slider) => (
                  <Slider
                    key={slider.key}
                    {...slider}
                    show={current_data === slider.key}
                  />
                ))}
              </div>
            )}

            {/*<div>{sliders_content.data.map((slider) => (
          
          <Slider
            key={slider.key}
            {...slider}
            show={current_data === slider.key}
          />
        ))}</div>*/}
          </Modal.Body>
        </Modal>
        {/*<div className="btn-div">
          <a href="/nosotros/covid" className="btn-covid19 btn-moreInfo">
              <div className="btn-content">
              <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
              <p style={{ margin: "0px 20px" }}>CONOCE MÁS </p>
              <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
              </div>
          </a>
        </div>*/}
      </div>
      {/*
      <p className="section-title slider-title">¿Qué logramos en el 2020?</p>
      <div className="slider-container">
        <img className="prueba" src={sliderCohete1} alt="sliderCohete1" />
        <div>
          <img
            className="arrow-slider"
            src={arrowLeftSlider}
            alt="arrowLeftSlider"
            onClick={() => prevSlider(current_slider, setCurrent_slider)}
          />
        </div>
        {sliders_content.data.map((slider) => (
          <Slider
            key={slider.key}
            {...slider}
            show={current_slider === slider.key}
          />
        ))}
        <div>
          <img
            className="arrow-slider"
            src={arrowRightSlider}
            alt="arrowRightSlider"
            onClick={() =>
              nextSlider(
                current_slider,
                setCurrent_slider,
                sliders_content.data.length
              )
            }
          />
        </div>
      </div>
      <br />
      <br />
      <div className="btn-div">
        <a
          href="https://covid19.minsa.gob.pe/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-covid19 btn-moreInfo"
        >
          <div className="btn-content" style={{ padding: "5px 20px" }}>
            <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
            <p style={{ maxWidth: "22rem", margin: "0px 10px" }}>
              REVISA LA INFORMACIÓN DEL GOBIERNO EN RELACIÓN AL COVID-19{" "}
            </p>
            <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
          </div>
        </a>
          </div>*/}
      <br />

      {/*<div className="next-phase-background" style={{ marginTop:"20px"}}>
            <a href="/nosotros/fase4" className="next-phase-btn wobble-hor-bottom">
                <img src={smallRocket}/>
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ margin: "0px", fontSize: "15px" }}>Conoce qué hicimos en la siguiente fase</p>
                <img src={arrowRigth} className="arrow-icon" alt="arrowRight" />
                
              
              </a>
          </div>*/}

      {/* <div className="next-phase-background" style={{ marginTop: "20px" }}> */}
      {/*   <a href="/nosotros/fase4" className="next-phase-btn wobble-hor-bottom"> */}
      {/**/}
      {/*     <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "} */}
      {/*     <p className="next-phase-btn-text" style={{ margin: "0px 2px", fontSize: "1.2rem" }}>Conoce qué hicimos en la siguiente fase</p> */}
      {/*     <img src={arrowRigth} className="arrow-icon" alt="arrowRight" /> */}
      {/**/}
      {/*   </a> */}
      {/**/}
      {/* </div> */}
      <img
        src={coheteLuna}
        style={{ zIndex: 1, marginBottom: '24px', top: '1rem' }}
        alt=''
        className='cohete'
      />
      <div className='next-phase-background' style={{ marginTop: '20px' }}>
        <a
          href={props.pdfMemoria}
          target='_blank'
          className='next-phase-btn wobble-hor-bottom'
          download
        >
          <img src={arrowLeft} className='arrow-icon' alt='arrowLeft' />{' '}
          <p
            className='next-phase-btn-text'
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 2px',
              fontSize: '1.2rem',
            }}
          >
            Descargar Memoria
          </p>
          <img src={arrowRigth} className='arrow-icon' alt='arrowRight' />
        </a>
        <a
          href={props.pdfResumen}
          target='_blank'
          className='next-phase-btn wobble-hor-bottom'
          download
        >
          <img src={arrowLeft} className='arrow-icon' alt='arrowLeft' />{' '}
          <p
            className='next-phase-btn-text'
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 2px',
              fontSize: '1.2rem',
            }}
          >
            Descargar Resumen
          </p>
          <img src={arrowRigth} className='arrow-icon' alt='arrowRight' />
        </a>
      </div>
    </div>
  )
}
export default Covid

import React from "react";
import { useHistory } from "react-router-dom";

import "./../../cuidador/areas/areas.css";
import "./modulos.css";
import iconoTipLighBlue from "./../../../../images/icon-tip-ligh-blue.svg";
import { useCollection } from "react-firebase-hooks/firestore";
import { auth, db } from "../../../../components/firebase/firebase";
import arrTips from "../../../../data";
import arrowLeft from "./../../../../images/arrow-left-blue.svg";
import arrowRight from "./../../../../images/arrow-right-blue.svg";
import { Button, Modal } from "react-bootstrap";
import ModuloModal from "./ModuloModal"
import ModuloTemplate from "./ModuloTemplate"
import { useAliados } from "../../../../customHooks"

const Modulos = ({ firebaseUser }) => {
  const [showModulo, setShowModulo] = React.useState(false);
  const [indexSelection, setIndexSelection] = React.useState(null)

  const handleCloseModulo = () => setShowModulo(false);
  const handleShowModulo = () => setShowModulo(true)

  const [data, setData] = useAliados('tipoAliado')
  let history = useHistory();

  return (
    <div className="animated fadeIn">
      <div>
        <div className="box-title-modulo show-desktop">
          <h1 className="title-modulo  tracking-in-expand-fwd-top">
            Visita nuestros módulos <br /> y aprender con volar
          </h1>
        </div>
        <div className="list-modulo">
          <a href="/aprendamos/aliado">
            <i
              className="fas fa-chevron-left arrowGoback goBack-area"
              aria-hidden="true"
            ></i>
          </a>
          <div className="container-fluid">
            <div className="row">
              {data && data.map((value, index) => {
                return <ModuloTemplate key={index} i={index} setIndexSelection={setIndexSelection} data={value} handleShowModulo={handleShowModulo} showModulo={showModulo} />
              })}
            </div>
          </div>
        </div>
      </div>
      {data && indexSelection !== null && <ModuloModal indexSelection={indexSelection} showModulo={showModulo} onHide={handleCloseModulo} data={data} />}
      {/* <Modal show={showModulo1} onHide={handleCloseModulo1}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="container animated fadeIn">
            <div className="">
              <div>
                <div className="row">
                  <div className="col-12">
                    <h4 className="tittle-letter-modal">¡Felicitaciones!</h4>
                    <p className="letter-modal">
                      Acabas de empezar tu formación como Aliado por la
                      infancia. Para este primer tema te dejamos los siguientes
                      materiales:
                    </p>
                  </div>
                </div>
                <div className="caja-boton-rol">
                  <a
                    type="submit"
                    className="btn-navy-blue font-white mt-3 mb-3 wobble-hor-bottom out-none-button link"
                    data-dismiss="modal"
                    href="/aprendamos/aliado/modulos/modulo_1"
                  >
                    <img src={arrowLeft} className="arrow-blue"></img>
                    VER MÁS AQUÍ
                    <img src={arrowRight} className="arrow-blue"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default Modulos;

import React from "react";
import { Link } from "react-router-dom";

import arrowLeft from "../../images/arrow-left-orange.svg";
import arrowRigth from "../../images/arrow-right-orange.svg";
import arrowLeftPurple from "../../images/arrow-left-purple.svg";
import arrowRigthPurple from "../../images/arrow-right-purple.svg";

export const Modular = ({
  number,
  width,
  phase,
  name,
  url,
  background,
  boxShadow,
}) => {
  return (
    <div
      style={{
        justifySelf: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <p className="timeline-text" style={{ width: `${width}` }}>
        {phase}
      </p>
      <p className="timeline-text-bold heartbeat" style={{ width: `${width}` }}>
        {name}
      </p>
      <Link to={url} className="">
        <div
          className="timeline-btn"
          style={{
            background: `${background || "#ef8b44"}`,
            boxShadow: `3px 4px 0px ${boxShadow || "#ce6a24"}`,
          }}
        >
          <img
            src={`${number === "4" ? arrowLeftPurple : arrowLeft}`}
            className="timeline-arrow-orange"
            alt="arrowLeft"
          />
          VER MÁS
          <img
            src={`${number === "4" ? arrowRigthPurple : arrowRigth}`}
            className="timeline-arrow-orange"
            alt="arrowRigth"
          />
        </div>
      </Link>
    </div>
  );
};

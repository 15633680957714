import React from "react";
import { Link } from "react-router-dom";

import chatIcon from "../../../images/covid19/chat-icon.svg";

export const Advice = ({ fields, id }) => {
  console.log("fields", fields)
  return (
    <Link to={{
      pathname: `/covid19/consejos/${id}`, state: { data: fields }
    }} >
      <div className="advice">
        <div>
          <img src={chatIcon} className="chat-icon" alt="chatIcon" />
        </div>
        <div>
          <p className="advice-title">{fields.nombre}</p>
          <p className="advice-text">CONSEJO N° {id + 1}</p>
        </div>
      </div>
    </Link >
  );
};

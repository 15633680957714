import React from 'react'
import arrowLeft from "./../../../../images/arrow-left-blue.svg";
import arrowRight from "./../../../../images/arrow-right-blue.svg";

const ButtonInfo = ({ tipoMaterial }) => {

  return (

    <a href={tipoMaterial.fields.urlBoton} className="btn-aprendamos-aliados">
      <div className="mt-5 btn-azul-aprendamos ">
        <img src={arrowLeft} className="arrow-blue"></img>
        APRENDAMOS
        <img src={arrowRight} className="arrow-blue"></img>
      </div>
    </a>


  )
}

export default ButtonInfo

import React from 'react'
import { useHistory } from 'react-router-dom'
import './fase1.css'
import modelos from '../../../images/fase1-img/modelo-cultural.svg'
import mapeo from '../../../images/fase1-img/mapeo.svg'
import investigacion from '../../../images/fase1-img/investigacion.svg'
import info from '../../../images/fase1-img/contenido_infografia1.png'
import infoDesktop from '../../../images/fase1-img/infografia-desktop.png'
import starsleft from '../../../images/fase1-img/stars-info-left.svg'
import starsRigth from '../../../images/fase1-img/stars-info-rigth.svg'
import arrowLeftBlue from '../../../images/arrow-left-blue.svg'
import arrowRightBlue from '../../../images/arrow-right-blue.svg'
import arrowLeft from '../../../images/arrow-left-orange.svg'
import arrowRigth from '../../../images/arrow-right-orange.svg'
import leftArrow from '../../../images/nosotros-img/left-arrow-phases-orange.svg'
import rightArrow from '../../../images/nosotros-img/right-arrow-phases-orange.svg'
import smallRocket from '../../../images/nosotros-img/small-rocket.svg'
import modeloculturales from '../../../images/pdf/modelos-culturales.pdf'
import volarreporte from '../../../images/pdf/VOLAR_REPORTE.pdf'
import { useNosotros } from '../../../customHooks'

const Fase1 = () => {
  let history = useHistory()
  const [data, setData] = useNosotros('7zWW2Zr8ivXROS7r97o6Np')
  return (
    <div className='animated fadeIn'>
      <div className='fase1 border-bottom-radius flex-fase1'>
        {/*<a
          onClick={() => {
            history.goBack();
          }}
        >
          <i className="fas fa-chevron-left goBack-fase" aria-hidden="true"></i>
        </a>*/}
        <div style={{ width: '5%' }}>
          <a
            href='/nosotros'
            style={{ display: 'flex', alignContent: 'flex-start' }}
          >
            <img src={leftArrow} />
          </a>
        </div>
        <div>
          <h6 className='titulo-numero-fase'>Fase 1</h6>
          <h5 className='titulo-fase tracking-in-expand-fwd-top'>{data?.fase1.fields.nombreDeLaFase}</h5>
          <p className='p-mobile-blue w-fase'>{data?.fase1.fields.textoIntroductorio}</p>
          {/* <p className='p-mobile-blue w-fase'> */}
          {/*   En esta fase, se buscó entender y reconocer la problemática del */}
          {/*   desarrollo infantil temprano, la cual es compleja, multidimensional */}
          {/*   y multiactor. */}
          {/* </p> */}
        </div>
        <div style={{ width: '5%' }}>
          <a
            href='/nosotros/fase2'
            style={{ display: 'flex', alignContent: 'flex-end' }}
          >
            <img src={rightArrow} />
          </a>
        </div>
      </div>
      <div className='mt-4-fase p-2'>
        <h6 className='title-dit'>
          Asimismo, esta fase contó con tres tipos de investigación para
          entender la problemática DIT:
        </h6>
      </div>
      <div className='box-fase1'>
        <div className='box-fase1-dit'>
          <div>
            <img src={modelos} className=''></img>
          </div>
          <p>
            Identificar los diversos culturales que existen en las y los
            peruanos sobre la primera infancia.
          </p>
        </div>
        <div className='box-fase1-dit'>
          <div>
            <img src={mapeo} className=''></img>
          </div>
          <p>
            Mapeo y análisis de la evidencia científica sobre las intervenciones
            en DIT que funcionan
          </p>
        </div>
        <div className='box-fase1-dit'>
          <div>
            <img src={investigacion} className=''></img>
          </div>
          <p>
            Investigación etnográfica sobre el estado del DIT en las zonas de
            influencia
          </p>
        </div>
      </div>
      <div className='box-infografia border-top-radius '>
        <h6>
          Como resultado de la evidencia y la investigación, Volar decidió
          enfocarse principalmente en dos ejes que denominamos Crecer Mejor y
          Agua Segura:
        </h6>
        <img src={starsleft} className='starsleft-info heartbeat'></img>
        <img src={starsRigth} className='starsRigth-info heartbeat'></img>
        <figure className='w-100'>
          <img src={info} className='w-100 hide-desktop '></img>
          <img src={infoDesktop} className='info-desktop hide-mobile '></img>
        </figure>
        <ul className='list-info'>
          <li className='li-celeste'>
            Al enfocarnos en Agua Segura, impactamos directamente en las
            dimensiones del modelo de nurturing care en salud e, indirectamente,
            en nutrición, seguridad y protección.
          </li>
          <li className='li-naranja'>
            De igual forma, al intervenir en Crecer Mejor, trabajamos
            directamente los aspectos relacionados con el aprendizaje temprano,
            el apego seguro, seguridad y protección.
          </li>
        </ul>
        <div className='box-botones-fase'>
          <a
            href={modeloculturales}
            download='Antes-de-caminar-y-hablar-final2018'
            className='wobble-hor-bottom link'
            target='_blank'
          >
            <div className='btn-fase-celeste'>
              <img src={arrowLeftBlue} className='arrow-orange'></img>
              <div>¡Descubre los modelos culturales!</div>
              <img src={arrowRightBlue} className='arrow-orange'></img>
            </div>
          </a>
          <a
            href={volarreporte}
            download='Memoria-de-volar'
            className='wobble-hor-bottom'
            target='_blank'
          >
            <div className='btn-fase-naranja'>
              <img src={arrowLeft} className='arrow-orange'></img>
              <div className='ml-2 mr-2'>APRENDE MÁS</div>
              <img src={arrowRigth} className='arrow-orange'></img>
            </div>
          </a>
        </div>
      </div>
      <div className='next-phase-background' style={{ marginTop: '20px' }}>
        {/* <a href='/nosotros/fase2' className='next-phase-btn wobble-hor-bottom'> */}
        {/*   <img src={arrowLeft} className='arrow-icon' alt='arrowLeft' />{' '} */}
        {/*   <p */}
        {/*     className='next-phase-btn-text' */}
        {/*     style={{ */}
        {/*       display: 'flex', */}
        {/*       justifyContent: 'center', */}
        {/*       margin: '0px 2px', */}
        {/*       fontSize: '1.2rem', */}
        {/*     }} */}
        {/*   > */}
        {/*     Descargar Memoria */}
        {/*   </p> */}
        {/*   <img src={arrowRigth} className='arrow-icon' alt='arrowRight' /> */}
        {/* </a> */}
      </div>
    </div>
  )
}

export default Fase1

import React from "react";

export const Component = ({ image, title, text, show }) => {
  return (
    <div className="component-container" hidden={!show}>
      <div className="component-item component-icon-text">
        <img className="component-img" src={require(`../../../images/nosotros-img/${image}`)} alt="componentsIcon" />
        <p className="component-title" style={{ color: "#47cccd" }}>
          {title}
        </p>
      </div>
      <div className="component-item" style={{ alignItems: "flex-end" }}>
        <p
          className="component-text"
          style={{ color: "#2E335E", marginBottom: "0px" }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

import React from "react";
import styled from "styled-components";
import labanda from "../../images/la banda.png";
import arrowLeft from "../../images/arrow-left-orange.svg";
import arrowRigth from "../../images/arrow-right-orange.svg";
import start from "../../images/star.svg";
import { usePopup } from "../../customHooks";

const ModalContent = ({ setOpen }) => {
  const [data, _] = usePopup("2ZWoRXMO5i4UFQl14XwPsR");
  console.log("popup", data);
  return (
    <ModalContainer>
      <button onClick={() => setOpen(false)} className="closeButton">
        <span>x</span>
      </button>
      <img
        className="startModal star-left heartbeat"
        width={"15%"}
        src={start}
      />
      <div className="left">
        <img src={labanda} style={{ maxWidth: "100%" }} alt="Modal" />
      </div>
      <div className="right">
        <div className="titleModal">{data?.titulo}</div>
        <div className="contentModal">{data?.contenido}</div>
        <div className="buttonsContainer">
          {data?.memoriaCompleta && (
            <div>
              <a
                href={data.memoriaCompleta.fields.file.url}
                target="_blank"
                className="btn-orange text-white wobble-hor-bottom "
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                // style={{ backgroundColor: '#ef8b44', color: 'white',fontWeight:'900' }}
                download
              >
                <img src={arrowLeft} className="arrow-orange"></img>
                Descarga la memoria completa
                <img src={arrowRigth} className="arrow-orange"></img>
              </a>
            </div>
          )}
          {data?.resumenMemoria && (
            <div>
              <a
                href={data.resumenMemoria.fields.file.url}
                target="_blank"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className="btn-orange text-white wobble-hor-bottom "
                // style={{ backgroundColor: '#ef8b44', color: 'white',fontWeight:'900' }}
                download
              >
                <img src={arrowLeft} className="arrow-orange"></img>
                Descarga el resumen
                <img src={arrowRigth} className="arrow-orange"></img>
              </a>
            </div>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default ModalContent;

const ModalContainer = styled.div`
  display: grid;
  position: relative;
  width: 75%;
  align-items: center;
  grid-template-columns: 2fr 1.2fr;
  background-color: #f6f6f3;
  padding: 10px 30px;
  gap: 32px;
  border-radius: 15px;
  border: 4px solid #7dc7c5;
  font-family: "Questrial", sans-serif;
  .closeButton {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 0rem 1rem;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: none;
    opacity: 0.5;
    span {
      font-size: 1.5rem;
      font-weight: 700;
      text-shadow: 0 1px #fff;
    }
  }

  .startModal {
    position: absolute;
    left: 0px;
    top: 0px;
  }

  .left {
    .img {
    }
  }

  .right {
    display: flex;
    padding: 30px 0;
    flex-direction: column;
    gap: 24px;

    .titleModal {
      text-align: center;
      font-weight: 900;
      text-transform: uppercase;
      font-size: 1.1rem;
      color: #7dc7c5;
    }

    .contentModal {
      text-align: center;
      color: #2e3353;
      font-size: 1rem;
      letter-spacing: 0.07rem;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      maxwidth: 60%;
      gap: 16px;

      div {
        display: flex;
        justify-content: center;
      }

      a {
        display: flex;
        font-size: 0.7vw;
        text-align: center;
        img {
          padding: 0 2px;
        }
      }
    }
  }

  @media only screen and (max-width: 770px) {
    height: 70%;
    grid-template-columns: auto;
    grid-template-rows: 1fr 2fr;
    gap: 0px;
    .right {
      padding: 0px 0px;
      .titleModal {
        font-size: 0.8rem;
      }

      .contentModal {
        font-size: 1.2vh;
      }

      .buttonsContainer {
        a {
          vertical-align: middle;
          font-size: 1.1vh;
          display: flex;
          align-items: center;
        }
      }
    }
  }
`;

import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./home.css"; import fondomobile from "../../images/home-img/img-mobile.png";
import proposito from "../../images/home-img/proposito.svg";
import cognitivo from "../../images/home-img/d-cognitivo.svg";
import emocional from "../../images/home-img/d-emocional.svg";
import fisico from "../../images/home-img/d-fisico.svg";
import cohete from "../../images/cohete-stars.svg";
import groupNosotros from "../../images/group-nosotros.svg";
import arrowLeft from "../../images/arrow-left-orange.svg";
import arrowRigth from "../../images/arrow-right-orange.svg";
import Modal from '@material-ui/core/Modal';
import bannerMobile1 from "../../images/home-img/banner-mobile/banerM1.png";
import bannerMobile2 from "../../images/home-img/banner-mobile/banerM2.png";
import bannerMobile3 from "../../images/home-img/banner-mobile/banerM3.png";
import bannerMobile4 from "../../images/home-img/banner-mobile/banerM4.png";
import ModalContent from './ModalContent'
import bannerDesktop1 from "../../images/home-img/banner-desktop/banerD1.png"; import bannerDesktop2 from "../../images/home-img/banner-desktop/banerD2.png";
import bannerDesktop3 from "../../images/home-img/banner-desktop/banerD3.png";
import bannerDesktop4 from "../../images/home-img/banner-desktop/banerD4.png";

import starLeft from "../../images/home-img/star-left.svg";
import starRight from "../../images/home-img/star-right.svg";

import share from "../../images/home-img/share-morado.svg";
import shareF from "../../images/home-img/share-fuxia.svg";
import shareC from "../../images/home-img/share-celeste.svg";
import shareA from "../../images/home-img/share-amarillo.svg";
import tituloHome from "../../images/home-img/titulo_home.png";
import CarouselMobile from "./CarouselMobile";
import CarouselDesktop from "./CarouselDesktop";
import videoo from "../../images/video/home_desktop_1.mp4";
import habla from "../../images/home-img/hablaYcanta.svg";
import exploren from "../../images/home-img/exploren.svg";
import lean from "../../images/home-img/lean.svg";
import cuenten from "../../images/home-img/cuenten.svg";

import { Helmet } from "react-helmet";
import { client } from "../../contentful"
import { getContentfulData, recursivePhotoGroup } from "../../utils"
import { useHomePageHook } from "../../customHooks"

const Home = () => {
  const width = window.innerWidth;
  const breakpoint = 769;

  const [homePage] = useHomePageHook()

  const [open, setOpen] = React.useState(true)

  return (
    <div className="animated fadeIn">
      <Helmet>
        <title>Inicio - Volar</title>
        <meta
          name="description"
          content="Volar es un proyecto de Aporta Desarrollo Sostenible, la plataforma de impacto social del Grupo Breca, diseñado con la intención de que las niñas y niños peruanos de 0 a 5 años alcancen su máximo potencial."
        />
        <meta name="keywords" content="Volar,volar"></meta>
        <meta name="URL" content="https://volar.org.pe/" />
        <meta name="author" content="volar.orge.pe" />
        <meta name="distribution" content="global" />
        <meta
          property="og:image"
          content="https://www.volar.org.pe/static/media/banerD1.50e030d2.png"
        />
        <meta property="og:url" content="https://volar.org.pe/" />
        <meta property="og:type" content="https://volar.org.pe/" />
        <meta property="og:title" content="Home - Volar" />
        <meta
          property="og:description"
          content="Volar es un proyecto de Aporta Desarrollo Sostenible, la plataforma de impacto social del Grupo Breca, diseñado con la intención de que las niñas y niños peruanos de 0 a 5 años alcancen su máximo potencial."
        /> <meta />
        <meta name="twitter:title" content="Home - Volar" />
        <meta
          name="twitter:description"
          content="Volar es un proyecto de Aporta Desarrollo Sostenible, la plataforma de impacto social del Grupo Breca, diseñado con la intención de que las niñas y niños peruanos de 0 a 5 años alcancen su máximo potencial."
        />
        <meta
          name="twitter:image"
          content="https://www.volar.org.pe/static/media/banerD1.50e030d2.png"
        />
      </Helmet>
      <div className="video-bg ">
        <video
          className="hide-mobile"
          width="500"
          height="600"
          loop
          autoPlay
          muted
        >
          <source src={videoo} type="video/mp4" />
          <source src={videoo} type="video/ogg" />
          <source src={videoo} type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <img
          src={fondomobile}
          alt=""
          className=" fondo-mobile-home show-mobile"
        />
      </div>
      <Modal
        open={open}
        onClose={() => setOpen(!open)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <ModalContent setOpen={setOpen} />
      </Modal>
      <div className="text-center text-white text-proposito">
        <div className="d-flex justify-content-center  text-center text-white">
          {/* <h1 className="hide-desktop">nuestro</h1> */}
          {/* <img src={proposito} className="megafono hide-desktop" ></img> */}
          <img
            src={tituloHome}
            alt=""
            className="titulo-home tracking-in-expand-fwd-top"
          />
        </div>
        {/* <h1 className="h1-proposito hide-desktop">propósito</h1> */}
        <p className="">
          {homePage.propositoParrafo1}
        </p>
        <p className="">
          {homePage.propositoParrafo2}<br></br>
          <strong className="fuerte-desktop">
            {homePage.propositoParrafo3Negrita}
          </strong>{" "}
        </p>

        <a
          href="https://youtu.be/_rAy16B7eJs"
          className="btn-orange text-white wobble-hor-bottom"
          target="_blank"
        >
          <img src={arrowLeft} className="arrow-orange"></img>
          ¡Entérate más!
          <img src={arrowRigth} className="arrow-orange"></img>
        </a>
      </div>
      <div className="container">
        <div className="nosotros-home">
          <figure className="stars-home">
            <img src={starLeft} className="star-left heartbeat"></img>
            <img src={starRight} className=" star-right heartbeat"></img>
          </figure>

          <h2 className="mt-nosotros">nosotros</h2>
          <p className="text-blue-volar">
            {homePage.nosotros}
          </p>
          <p className="text-aguamarina estrellita-home">&#10022;</p>
          <p className="text-aguamarina subtitulo-home">
            Está enfocado en ofrecer estrategias de crianza que fortalezcan:
          </p>
          <div className="box-estrategias">
            <div className="card img-des-cognitivo">
              <img src={cognitivo} className="card-img-top mt-1 " alt="..." />
              <div className="card-body">
                <p className="card-text card-text-home">
                  Desarrollo<br></br> cognitivo
                </p>
              </div>
            </div>
            <div className="card">
              <img src={emocional} className="card-img-top " alt="..." />
              <div className="card-body">
                <p className="card-text card-text-home">
                  Desarrollo<br></br> Socio-emocional
                </p>
              </div>
            </div>
            <div className="card img-des-fisico">
              <img src={fisico} className="card-img-top" alt="..." />
              <div className="card-body">
                <p className="card-text card-text-home">
                  Desarrollo<br></br> Físico{" "}
                </p>
              </div>
            </div>
          </div>
          <a
            href="/nosotros"
            className="btn-orange text-white wobble-hor-bottom"
          >
            <img src={arrowLeft} className="arrow-orange"></img>
            nuestra historia
            <img src={arrowRigth} className="arrow-orange"></img>
          </a>
          <img src={cohete} className="cohete "></img>
        </div>
      </div>
      <div className="equipo-home">
        <h2>nuestro equipo</h2>
        <div className="container box-equipo-desktop">
          <div
            id="multi-item-example"
            className="carousel slide carousel-multi-item"
            data-ride="carousel"
          >
            <div className="controls-top">
              <a
                className="btn-floating"
                href="#multi-item-example"
                data-slide="prev"
              >
                <i className="fa fa-chevron-left"></i>
              </a>
              <a
                className="btn-floating"
                href="#multi-item-example"
                data-slide="next"
              >
                <i className="fa fa-chevron-right"></i>
              </a>
            </div>

            <ol className="carousel-indicators">
              <li
                data-target="#multi-item-example"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#multi-item-example" data-slide-to="1"></li>
              <li data-target="#multi-item-example" data-slide-to="2"></li>
              <li data-target="#multi-item-example" data-slide-to="3"></li>
              <li data-target="#multi-item-example" data-slide-to="4"></li>
              <li data-target="#multi-item-example" data-slide-to="5"></li>
            </ol>
            {width < breakpoint ? <CarouselMobile data={homePage} /> : <CarouselDesktop data={homePage} />}
          </div>
        </div>
      </div>
      {width < breakpoint ? (
        <div className="momento-home">
          <div
            id="carouselExampleCaptions"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleCaptions"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item img-banner active">
                <img src={bannerMobile1} className="d-block w-100 " alt="..." />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={share} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="">Habla, canta y señala</h4>
                      <img src={habla} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
              <div className="carousel-item">
                <img src={bannerMobile2} className="d-block w-100" alt="..." />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={shareF} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="text-fuxia">
                        Exploren mediante el movimiento y el juego
                      </h4>
                      <img src={exploren} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
              <div className="carousel-item">
                <img src={bannerMobile3} className="d-block w-100" alt="..." />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={shareC} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="text-celeste">
                        LEAN Y COMENTEN HISTORIAS
                      </h4>
                      <img src={lean} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
              <div className="carousel-item">
                <img src={bannerMobile4} className="d-block w-100" alt="..." />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={shareA} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="text-amarilloo">
                        CUENTEN, AGRUPEN Y COMPAREN LAS COSAS
                      </h4>
                      <img src={cuenten} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleCaptions"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleCaptions"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      ) : (
        <div className="momento-home">
          <div
            id="carouselExampleCaptions"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleCaptions"
                data-slide-to="0"
                className="active"
              ></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
              <li data-target="#carouselExampleCaptions" data-slide-to="3"></li>
            </ol>
            <div className="carousel-inner">
              <div className="carousel-item  active">
                <img
                  src={bannerDesktop1}
                  className="d-block w-100 "
                  alt="..."
                />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={share} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="">Habla, canta y señala</h4>
                      <img src={habla} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
              <div className="carousel-item">
                <img src={bannerDesktop2} className="d-block w-100" alt="..." />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={shareF} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="text-fuxia">
                        Exploren mediante el movimiento y el juego
                      </h4>
                      <img src={exploren} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
              <div className="carousel-item">
                <img src={bannerDesktop3} className="d-block w-100" alt="..." />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={shareC} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="text-celeste">
                        LEAN Y COMENTEN HISTORIAS
                      </h4>
                      <img src={lean} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
              <div className="carousel-item">
                <img src={bannerDesktop4} className="d-block w-100" alt="..." />
                <div className="carousel-caption-text">
                  <h2>¡Cada momento cuenta!</h2>
                  <p>
                    ¿Sabías que tus actividades cotidianas son oportunidades
                    para el desarrollo del cerebro de tus hijas e hijos?
                  </p>
                </div>
                <div className="carousel-caption  d-md-block">
                  <div className="box-title-slide">
                    <a
                      href="https://www.facebook.com/sharer/sharer.php?u=https%3A//volar.org.pe/"
                      target="_blank"
                    >
                      <figure className=" share ">
                        <img src={shareA} className="share-img heartbeat"></img>
                      </figure>
                    </a>
                    <div className="title-slide">
                      <h4 className="text-amarilloo">
                        CUENTEN, AGRUPEN Y COMPAREN LAS COSAS
                      </h4>
                      <img src={cuenten} alt="" />
                    </div>
                  </div>
                  <Link to="/aprendamos" className="btn-orange text-white">
                    <img src={arrowLeft} className="arrow-orange"></img>
                    ¡Aprendamos a hacerlo!
                    <img src={arrowRigth} className="arrow-orange"></img>
                  </Link>
                </div>
              </div>
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleCaptions"
              role="button"
              data-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleCaptions"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      )}
    </div>
  );
};
export default Home;

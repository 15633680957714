import React from "react";

export const InfoItem = ({ icon, text, color }) => {
  return (
    <div className="info-icon-text">
      <img className="info-icon-text-img" src={icon} alt="icon" />
      <p className="info-icon-text-content" style={{ color }}>
        {text}
      </p>
    </div>
  );
};

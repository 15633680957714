import React from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import "./footer.css";
import aporta from "../../images/aporta-logo-final.png";
import breca from "../../images/breca-logo.svg";
import ubicacion from "../../images/ubicacion-logo.svg";
import telefono from "../../images/telefono-logo.svg";
import correo from "../../images/correo-logo.svg";
import facebook from "../../images/facebook.svg";
import instagram from "../../images/instagram.svg";
import youtube from "../../images/icon-youtube.svg";
import { useHomePageHook } from "../../customHooks"

const Footer = () => {

  const [homePage] = useHomePageHook()

  return (
    <div className="footer-box">
      <div className="box-convenio">
        <figure className="">
          <img src={aporta} className="aporta"></img>
        </figure>
        <figure className="mt-2">
          <img src={breca} className="breca"></img>
        </figure>
      </div>
      <div className="box-ubicacion">
        <img src={ubicacion} className="mb-3 ubicacion"></img>
        {homePage.ubicacion ? <p>{homePage.ubicacion.fields.tipo}</p> : ""}
        {homePage.ubicacion ? <p>{homePage.ubicacion.fields.calle}</p> : ""}
        {homePage.ubicacion ? <p className="text-small">{homePage.ubicacion.fields.ciudad}</p> : ""}
      </div>
      <div className="box-telefono">
        <img src={telefono} className="mb-3 telefono"></img>
        <p>Teléfono</p>
        {homePage.telefono ? <p>{homePage.telefono}</p> : ""}
      </div>
      <div className="box-correo">
        <img src={correo} className="mb-3 correo"></img>
        <p>Correo</p>
        {homePage.correo ? <p>{homePage.correo}</p> : ""}
        {/* <p>volar@aporta.org.pe</p> */}
      </div>
      <div className="box-redes">
        {homePage.urlInstagram ? (
          <figure>
            <a href={homePage.urlInstagram} target="_blank">
              <img src={instagram} className="icon-footer"></img>
            </a>
          </figure>
        ) : ""}
        {homePage.urlFacebook ? (
          <figure>
            <a href={homePage.urlFacebook} target="_blank">
              <img src={facebook} className="icon-footer"></img>
            </a>
          </figure>
        ) : ""}
        {homePage.urlYoutube ? (
          <figure>
            <a href={homePage.urlYoutube} target="_blank">
              <img src={youtube} className="icon-footer"></img>
            </a>
          </figure>
        ) : ""}
      </div>
      <a
        className="terminos-condiciones"
        href="https://drive.google.com/file/d/1AVacSukAo7wo7G2KUY_770wKXYae9FS2/view"
        target="_blanck"
      >
        <p className="text-blue-volar">Términos y condiciones</p>
      </a>
    </div>
  );
};
export default Footer;

import React from "react";
import { Link } from "react-router-dom";

import { Advice } from "./Advice";
import "./consejos.css";

import advices_content from "../../../content/advices.json";

import starsLeftDesktop from "./../../../images/stars-white-left.png";
import starsRightDesktop from "./../../../images/stars-white-right.png";
import starsLeftMobile from "../../../images/covid19/stars-white-mobile-left.svg";
import starsRightMobile from "../../../images/covid19/stars-white-mobile-right.svg";
import heartIcon from "../../../images/covid19/heart-icon.svg";
import leftArrow from "../../../images/nosotros-img/left-arrow-phases.svg";
import { useCovid19Consejos } from "../../../customHooks"


const Consejos = () => {

  const [data,] = useCovid19Consejos('6qmbFDzkEFQsH4pPaljkju')

  return (
    <div id="covid19-consejos-page">
      <div
        id="tittle-covid19-avices"
        className="box-covid19-advices animated fadeIn mt-3"
      >
        <div>
          <Link to="/covid19">
            <img src={leftArrow} alt="leftArrow" />
          </Link>
        </div>
        <div>
          <img
            src={starsLeftDesktop}
            className="stars heartbeat"
            alt="stars-left"
          />
          <img
            src={starsRightMobile}
            className="stars-mobile heartbeat"
            alt="stars-left"
          />
        </div>
        <div className="text-container">
          <img
            src={heartIcon}
            className="heart-icon tracking-in-expand-fwd-top"
            alt="heartIcon"
          />
          <h1 className="title-covid19-advices tracking-in-expand-fwd-top">
            Consejos COVID
          </h1>
        </div>
        <div>
          <img
            src={starsRightDesktop}
            className="stars heartbeat"
            alt="stars-right"
          />
          <img
            src={starsLeftMobile}
            className="stars-mobile heartbeat"
            alt="stars-right"
          />
        </div>
        <div></div>
      </div>
      <div className="banner-advices">
        <div className="advices-container">
          {data && data.listaConsejos.map((advice, index) => (
            <Advice key={advice.sys.id} id={index} {...advice} />
          ))}
        </div>
      </div>
      <br />
      <br />
    </div>
  );
};
export default Consejos;

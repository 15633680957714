import React from "react";
import { useHistory } from "react-router-dom";
import arrowLeft from "../../../images/covid19/arrow-left-gray.svg";
import arrowRigth from "../../../images/covid19/arrow-right-gray.svg";
import "./Fase3.css";
import duracion from "../../../images/fase3-img/duracion.svg";
import articulacion from "../../../images/fase3-img/articulacion.svg";
import evaluacion from "../../../images/fase3-img/evaluacion.svg";
import mejoras from "../../../images/fase3-img/mejoras.svg";
import reforzamiento from "../../../images/fase3-img/reforzamiento.svg";
import cohete from "../../../images/fase3-img/cohete-naranja.svg";
import coheteLuna from "../../../images/cohete-stars.svg";
import leftArrow from "../../../images/nosotros-img/left-arrow-phases.svg";
import rightArrow from "../../../images/nosotros-img/right-arrow-phases.svg";
import smallRocket from "../../../images/nosotros-img/small-rocket.svg";
import Evaluar from "../Evaluar/Evaluar";
import Covid from "../covid19/Covid19";
import { useNosotros } from "../../../customHooks";

const Fase3 = () => {
  let history = useHistory();
  const [is2020, setIs2020] = React.useState(null);
  const [data, setData] = useNosotros("7zWW2Zr8ivXROS7r97o6Np");

  const loadFirstTime = React.useRef(true);
  console.log("data", data);
  const update = () => {
    loadFirstTime.current = false;
  };

  return (
    <div className="animated fadeIn">
      <div className="fase1 fase3-image border-bottom-radius flex-fase3">
        {/*<a onClick={()=>{history.goBack()}}>
                <i  className="fas fa-chevron-left arrowGoback" aria-hidden="true"></i>
            </a>*/}
        <div style={{ width: "5%" }}>
          <a
            href="/nosotros/fase2"
            style={{ display: "flex", alignContent: "flex-start" }}
          >
            <img className="leftArrow" src={leftArrow} />
          </a>
        </div>
        <div>
          <h6 className="titulo-numero-fase">Fase 3</h6>
          <h5 className="titulo-fase tra;cking-in-expand-fwd-top">
            {data?.fase3.fields.nombreDeLaFase}
          </h5>
          <p className="p-mobile-blue w-fase">
            {data?.fase3.fields.textoIntroductorio}
          </p>
        </div>
        <div style={{ width: "5%" }}>
          <a
            href="/nosotros/fase4"
            style={{ display: "flex", alignContent: "flex-end" }}
          >
            <img className="rightArrow" src={rightArrow} />
          </a>
        </div>
      </div>
      <div
        className="2020-2021"
        style={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: 'center',
          marginTop: "24px",
          // margin: '24px 0px',
          alignItems: "center",
          gap: "12px",
        }}
      >
        <p
          // className='nosotros-covid-title'
          style={{ color: "#2E335E", fontWeight: "bold", fontSize: "24px" }}
        >
          ¿Cómo nos adaptamos a la coyuntura?
        </p>
        <div
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          {/* <div className='btn-div' style={{ marginTop: '20px' }}> */}
          <div className="btn-div">
            <a
              // href='/nosotros/'
              onClick={() => {
                setIs2020(true);
              }}
              style={{ color: "white" }}
              // style={{ backgroundColor: '#7dc7c5' }}
              className="btn-covid19 btn-moreInfo"
            >
              <div className="btn-content">
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ margin: "0px 20px" }}>2020</p>
                <img src={arrowRigth} className="arrow-icon" alt="arrowRight" />
              </div>
            </a>
          </div>
          <div className="btn-div">
            {/* <div className='btn-div' style={{ marginTop: '20px' }}> */}
            {/* <a href='/nosotros/fase4' className='btn-covid19 btn-moreInfo'> */}
            <a
              style={{ color: "white" }}
              onClick={() => {
                setIs2020(false);
              }}
              className="btn-covid19 btn-moreInfo"
            >
              <div className="btn-content">
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ margin: "0px 20px" }}>2021</p>
                <img src={arrowRigth} className="arrow-icon" alt="arrowRight" />
              </div>
            </a>
          </div>
        </div>

        {is2020 === null ? null : is2020 ? (
          <Covid pdfMemoria={data?.memoria2020.fields.file.url} pdfResumen={data?.resumen2020.fields.file.url} />
        ) : (
          <Evaluar pdfMemoria={data?.memoria2021.fields.file.url} pdfResumen={data?.resumen2021.fields.file.url} />
        )}
      </div>

    </div>
  );
};

export default Fase3;

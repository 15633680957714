import React from 'react'
import { useHistory } from 'react-router-dom'

import './Fase4.css'

import arrowLeft from '../../../images/arrow-left-orange.svg'
import sliders_content from '../../../content/slider_2021.json'
import arrowRigth from '../../../images/arrow-right-orange.svg'
import peru from '../../../images/fase4-img/mapa-peru.png'
import item from '../../../images/fase4-img/peru-item.png'
import internacional from '../../../images/fase4-img/clinica-internacional.png'
import libertador from '../../../images/fase4-img/libertador.png'
import urbanova from '../../../images/fase4-img/urbanova.png'
import minsur from '../../../images/fase4-img/minsur.png'
import aporta from '../../../images/fase4-img/aporta.png'
import qroma from '../../../images/fase4-img/qroma.png'
import tasa from '../../../images/fase4-img/tasa.png'
import centria from '../../../images/fase4-img/centria.png'
import rimac from '../../../images/fase4-img/rimac.png'
import aesa from '../../../images/fase4-img/aesa.png'
import leftArrow from '../../../images/nosotros-img/left-arrow-phases.svg'
import planVolar from '../../../images/fase4-img/seccion4.svg'
import teoriaDeCambio from '../../../images/fase4-img/seccion3.svg'
import evidencia from '../../../images/fase4-img/plan-volar.svg'
import coheteamarillo from '../../../images/fase4-img/coheteamarillo.svg'
import coheteceleste from '../../../images/fase4-img/coheteceleste.svg'
import sliderTasa from '../../../images/covid19/tasa_slider.svg'
import sliderMinsurfa from '../../../images/covid19/minsur-families_slider.svg'
import smsIcon from '../../../images/covid19/smsbig-icon.svg'
import meetingsIcon from '../../../images/nosotros-img/meetings-icon.svg'
import workshopsIcon from '../../../images/covid19/workshops-icon.svg'
import peopleIcon from "../../../images/nosotros-img/workshops-icon.svg";
import coheteLuna from '../../../images/cohete-stars.svg'
import internetIcon from '../../../images/nosotros-img/internet-icon.svg'
import videocallIcon from '../../../images/nosotros-img/video-call-icon.svg'
import { InfoItem } from '../../covid19/InfoItem-c19'
import { Modal } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { Slider2 } from '../covid19/Slider2'

const Evaluar = (props) => {
  let history = useHistory()

  const [showComunidades, setShowComunidades] = React.useState(false)
  const [showColaboradores, setShowColaboradores] = React.useState(false)
  const minsurData = sliders_content.data[0]
  // const [setShow#]

  return (
    <div className='animated fadeIn'>
      {/* <div className='fase1 fase4-fondo'> */}
      {/*<a onClick={()=>{history.goBack()}} >
                    <i className="fas fa-chevron-left goBack-fase" aria-hidden="true"></i> 
                </a>*/}

      {/* <div className='flex-fase4'> */}
      {/* <div style={{ width: '5%' }}> */}
      {/*   <a */}
      {/*     href='/nosotros/covid' */}
      {/*     style={{ display: 'flex', alignContent: 'flex-start' }} */}
      {/*   > */}
      {/*     <img src={leftArrow} /> */}
      {/*   </a> */}
      {/* </div> */}
      {/* <div> */}
      {/*   {/* <h6 className='titulo-numero-fase color-white'>Fase 4</h6> */} 
      {/*   <h5 className='titulo-fase4 tracking-in-expand-fwd-top'>Evaluar</h5> */}
      {/*   <p className='p-mobile-white w-fase'> */}
      {/*     A partir de los aprendizajes en las distintas fases y de las */}
      {/*     nuevas condiciones de operación ante la emergencia sanitaria por */}
      {/*     el COVID-19, Volar refinó su modelo de intervención, cuya versión */}
      {/*     optimizada se desplegliega en el 2021. Esta implementación está */}
      {/*     acompañada de un riguroso plan de medición que permitirá evaluar */}
      {/*     el impacto del modelo optimizado y, en base a ello, tomar */}
      {/*     decisiones respecto a su escalamiento. */}
      {/*   </p> */}
      {/* </div> */}
      {/* </div> */}
      {/* <p className="mt-2 estrellita-naranja heartbeat">&#10022;</p> */}
      {/* <h6 className="title-dit title-fase3-ajustes">Iniciamos nuestro recorrido en Puno</h6>
                <div className="box-mapa">
                    <figure className="mapa-peru">
                        <img src={peru} alt="" className="w-100"/>
                    </figure>
                    <figure className="mapa-item">
                        <img src={item} alt="" className=" pl-2 w-100"/>
                    </figure>
                </div> */}
      {/* </div> */}
      <div className='fase4'>
        <div className='seccion1'>
          <div className='seccion1-digital'>
            <h6 className='seccion1-title'>
              <img src={coheteceleste} alt='cohete' />
              <mark style={{ color: '#57CDCD', background: 'none' }}>
                Volar colaboradores
              </mark>
            </h6>
            <p className='seccion1-text-center'>
              Dirigido a las y los colaboradores que pertenecen a las empresas del grupo Breca y sus familias para brindarles acompañamiento que responda a sus necesidades en el desarrollo, educación y crianza de sus hijas e hijos.</p>
          </div>
          <div className='seccion1-digital'>
            <h6 className='seccion1-title'>
              <img src={coheteamarillo} alt='cohete' />
              <mark style={{ color: '#F9C045', background: 'none' }}>
                Volar comunidades
              </mark>
            </h6>
            <p className='seccion1-text-center'>
              Alterna la intervención presencial y a distancia en las comunidades que se encuentran en el área de influencia de las empresas del grupo Breca. Se busca impactar en las y los diversos actores involucrados en el desarrollo de niñas y niños menores de 5 años a través de la implementación de componentes diferenciados y personalizados que permitan incrementar el número de niñas y niños con indicadores de DIT apropiados para su edad.</p>
          </div>
        </div>
        <div className='seccion2'>
          <h6 className='seccion1-title'>
            Plan Volar{' '}
            <mark style={{ color: '#EF8B44', background: 'none' }}>2021</mark>
          </h6>
          <figure className='figure-fase4'>
            <img src={planVolar} alt='' className='w-100' />
          </figure>
        </div>
        <div className='new-section-row'>
          <div className='new-section-container'>
            <div
              className='new-section-item new-section-title'
            // style={{ display: 'flex', justifyContent: 'center' }}
            >
              <div style={{ width: 'fit-content' }}>
                <div
                  // onClick={() => setShowComunidades(true)}
                  className='new-plus-text'
                >
                  {/* <p className='new-plus-text'> */}
                  + Comunidades <br /> BRECA
                </div>
                <div
                  className='slider-img'
                  // onClick={() => {
                  //   handleChange(1)
                  //   handleShow()
                  // }}
                  style={{ display: 'flex', justifyContent: 'center' }}
                  onClick={() => setShowComunidades(true)}
                >
                  <div className='blank-box-color'>
                    <img className='image-show' src={sliderMinsurfa} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='new-section-container '>
            <div className='new-section-item'>
              <div style={{ width: 'fit-content' }}>
                <div
                  // onClick={() => setShowColaboradores(true)}
                  className='new-plus-text2'
                >
                  {/* <p className='new-plus-text2'> */}
                  + Colaboradores <br /> BRECA
                </div>
                <div
                  style={{ display: 'flex', justifyContent: 'center' }}
                  className='slider-img'
                  onClick={() => setShowColaboradores(true)}
                >
                  <div className='blank-box-color'>
                    <img className='image-show' src={sliderTasa} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          scrollable={true}
          dialogClassName='my-modal'
          show={showComunidades}
          onHide={() => setShowComunidades(false)}
        >
          <ModalHeader closeButton></ModalHeader>
          <Modal.Body className='info-container'>
            <Slider2 {...sliders_content.data[0]} show={true} />
          </Modal.Body>
        </Modal>
        <Modal
          scrollable={true}
          dialogClassName='my-modal'
          show={showColaboradores}
          onHide={() => setShowColaboradores(false)}
        >
          <ModalHeader closeButton></ModalHeader>
          <Modal.Body className='info-container'>
            <Slider2 {...sliders_content.data[1]} show={true} />
          </Modal.Body>
          <Modal.Body>
          </Modal.Body>
        </Modal>

        {/* <div className='seccion3'> */}
        {/*   <h6 className='seccion1-title'> */}
        {/*     Teoría de cambio{' '} */}
        {/*     <mark style={{ color: '#EF8B44', background: 'none' }}> */}
        {/*       Volar semi digital (Comunidades) */}
        {/*     </mark> */}
        {/*   </h6> */}
        {/*   <figure className='figure-fase4'> */}
        {/*     <img src={teoriaDeCambio} alt='' className='w-100' /> */}
        {/*   </figure> */}
        {/*   <p className='seccion1-text-center'> */}
        {/*     Para la{' '} */}
        {/*     <mark style={{ color: '#EF8B44', background: 'none' }}> */}
        {/*       medición de los resultados y el monitoreo{' '} */}
        {/*     </mark>{' '} */}
        {/*     de los avances del proyecto semi-digital, Volar ha puesto en marcha */}
        {/*     un plan de seguimiento y evaluación integral que permite generar */}
        {/*     evidencia relevante acerca de cada eslabón de la cadena de valor del */}
        {/*     proyecto. <br /> */}
        {/*     Se{' '} */}
        {/*     <mark style={{ color: '#EF8B44', background: 'none' }}> */}
        {/*       prevén reportes constantes */}
        {/*     </mark>{' '} */}
        {/*     para medir la transformación de insumos y actividades en los */}
        {/*     productos o componentes previstos, así como información sobre el */}
        {/*     cumplimiento de metas y la calidad en la entrega de las mismas. */}
        {/*     Adicionalmente,{' '} */}
        {/*     <mark style={{ color: '#EF8B44', background: 'none' }}> */}
        {/*       se disponen evaluaciones de corte cualitativo */}
        {/*     </mark>{' '} */}
        {/*     que permitan comprender los aciertos y espacios de mejora dentro del */}
        {/*     programa, entender los resultados observados y hacer las */}
        {/*     correcciones del caso sobre la marcha. Finalmente, se prevé una{' '} */}
        {/*     <mark style={{ color: '#EF8B44', background: 'none' }}> */}
        {/*       evaluación cuantitativa de impacto */}
        {/*     </mark>{' '} */}
        {/*     -con el Centro de Investigación de la Universidad Pacífico como */}
        {/*     aliado- que permita determinar los resultados del proyecto en los */}
        {/*     indicadores de desarrollo infantil y educación que puedan ser */}
        {/*     atribuidos al proyecto, utilizando instrumentos como el Modulo DIT – */}
        {/*     ENDES, CREDI y MELQO. */}
        {/*   </p> */}
        {/* </div> */}
        {/* <div className='seccion4'> */}
        {/*   <h6 className='seccion1-title'> */}
        {/*     Tipos de{' '} */}
        {/*     <mark style={{ color: '#EF8B44', background: 'none' }}> */}
        {/*       evidencia */}
        {/*     </mark> */}
        {/*   </h6> */}
        {/*   <figure className='figure-fase4'> */}
        {/*     <img src={evidencia} alt='' className='w-100' /> */}
        {/*   </figure> */}
        {/*   <p className='seccion1-text-center'> */}
        {/*     Durante el 2021, el proyecto Volar se encuentra postulando a fondos */}
        {/*     externos que permitan la realización de un RCT y contar así con */}
        {/*     evidencia del más alto nivel de rigurosidad con validez nacional. */}
        {/*   </p> */}
        {/* </div> */}
        {/* <div className='seccion5'> */}
        {/*   <h6 className='seccion1-title'> */}
        {/*     Volar es hoy un proyecto que{' '} */}
        {/*     <mark style={{ color: '#EF8B44', background: 'none' }}> */}
        {/*       reúne las 3E */}
        {/*     </mark> */}
        {/*   </h6> */}
        {/*   <div className='seccion5-boxes'> */}
        {/*     <div className='seccion5-boxes-celeste'>Eficiente</div> */}
        {/*     <div className='seccion5-boxes-lila'>Evaluable</div> */}
        {/*     <div className='seccion5-boxes-naranja'>Escalable</div> */}
        {/*   </div> */}
        {/* </div> */}
        {/* <div className="box-empresas">
                <div className="box-empresa-title">
                    <h6 className="empresa-tilte">EMPRESAS</h6>
                </div>
                <div className="box-empresas-logos">
                    <figure className="logo-figure-empresa">
                        <img src={internacional} alt="" className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={libertador} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={urbanova} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={minsur} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={aporta} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={qroma} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={tasa} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={centria} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={rimac} alt=""className="w-100"/>
                    </figure>
                    <figure className="logo-figure-empresa">
                        <img src={aesa} alt=""className="w-100"/>
                    </figure>
                </div>
            </div> */}
      </div>
      <img
        src={coheteLuna}
        style={{ zIndex: 1, marginBottom: '24px', top: '1rem' }}
        alt=''
        className='cohete'
      />
      <div className='next-phase-background' style={{ marginTop: '20px' }}>
        <a
          href={props.pdfMemoria}
          target='_blank'
          className='next-phase-btn wobble-hor-bottom'
          download
        >
          <img src={arrowLeft} className='arrow-icon' alt='arrowLeft' />{' '}
          <p
            className='next-phase-btn-text'
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 2px',
              fontSize: '1.2rem',
            }}
          >
            Descargar Memoria
          </p>
          <img src={arrowRigth} className='arrow-icon' alt='arrowRight' />
        </a>
        <a
          href={props.pdfResumen}
          target='_blank'
          className='next-phase-btn wobble-hor-bottom'
          download
        >
          <img src={arrowLeft} className='arrow-icon' alt='arrowLeft' />{' '}
          <p
            className='next-phase-btn-text'
            style={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 2px',
              fontSize: '1.2rem',
            }}
          >
            Descargar Resumen
          </p>
          <img src={arrowRigth} className='arrow-icon' alt='arrowRight' />
        </a>
      </div>
    </div>
  )
}

export default Evaluar

import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./covid19.css";

import sliders_content from "../../content/slider.json";

import arrowLeft from "../../images/covid19/arrow-left-gray.svg";
import arrowRight from "../../images/covid19/arrow-right-gray.svg";
//import starsLeftDesktop from "./../../images/stars-white-left.png";
//import starsRightDesktop from "./../../images/stars-white-right.png";
import starsLeftMobile from "../../images/covid19/stars-white-mobile-left.svg";
import starsRightMobile from "../../images/covid19/stars-white-mobile-right.svg";

import starsLeftDesktop from "./../../images/stars-left-desktop.svg";
import starsRightDesktop from "./../../images/stars-right-desktop.svg";
import starsRight from "../../images/stars-right.svg";

import playIcon from "../../images/covid19/play-icon.svg";
import adviceIcon from "../../images/covid19/advice-icon.svg";
import resourcesIcon from "../../images/covid19/resources-icon.svg";

import volunteeringIcon from "../../images/covid19/volunteering-icon.svg";
import workshopsIcon from "../../images/covid19/workshops-icon.svg";
import connectivityIcon from "../../images/covid19/connectivity-icon.svg";
import smsIcon from "../../images/covid19/smsbig-icon.svg";
import contentIcon from "../../images/covid19/content-icon.svg";
import teacherIcon from "../../images/covid19/teacher-icon.svg";
import internetIcon from "../../images/nosotros-img/internet-icon.svg";
import peopleIcon from "../../images/nosotros-img/workshops-icon.svg";
import videocallIcon from "../../images/nosotros-img/video-call-icon.svg";

import maxSmsIcon from "../../images/nosotros-img/maxSms-icon.svg";
import maxWorkshopsIcon from "../../images/nosotros-img/maxWorkshops-icon.svg";
import maxResourcesIcon from "../../images/nosotros-img/maxResources-icon.svg";
import maxMeetingsIcon from "../../images/nosotros-img/maxMeetings-icon.svg";

import sliderCohete1 from "../../images/covid19/slider-cohete1.svg";
import arrowLeftSlider from "../../images/covid19/arrow-left-slider.svg";
import arrowRightSlider from "../../images/covid19/arrow-right-slider.svg";
import logo from "../../images/menu-img/logo-volar.svg";
import meetingsIcon from "../../images/nosotros-img/meetings-icon.svg";

import { Modal, ModalFooter } from "react-bootstrap";
import play from "../../images/covid19/play.png";

import nutrition from "../../images/covid19/nutrition-development.png";
import games from "../../images/covid19/play-game.png";
import cuidandonos from "../../images/covid19/take-care.png";
import aprender from "../../images/covid19/teach-develop.png";
import agua from "../../images/covid19/water-secure.png";

import ModalHeader from "react-bootstrap/esm/ModalHeader";

import { InfoItem } from "./InfoItem-c19";
import { Item } from "./Item-c19";

const Covid19 = () => {
  const [current_slider, setCurrent_slider] = useState(1);
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const [title, newTitle] = useState("");
  const [url, newUrl] = useState("");

  const prevSlider = (value, setValue) => {
    if (value !== 1) {
      setValue(value - 1);
    }
  };

  const nextSlider = (value, setValue, max) => {
    if (value !== max) {
      setValue(value + 1);
    } else {
      setValue(1);
    }
  };

  const handleClose = () => setShow(!show);
  const handleShow = () => setShow(!show);

  const handleClose2 = () => setShow2(!show2);

  function handleShow2(ntitle, nurl) {
    setShow2(!show2);
    newTitle(ntitle);
    newUrl(nurl);
  }

  const handleClose3 = () => setShow3(!show3);
  const handleShow3 = () => setShow3(!show3);

  const handleClose4 = () => setShow4(!show4);
  const handleShow4 = () => setShow4(!show4);

  return (
    <div id="covid19-page">
      <div className="box-title-container mt-3">
        <div id="tittle-covid19" className="box-title-covid19 animated fadeIn">
          <figure className="stars-group">
            <img
              src={starsLeftDesktop}
              className="stars-left-desktop-covid19 heartbeat"
              alt="starsLeftDesktop"
            />
            <img
              src={starsRightDesktop}
              className="stars-right-desktop-covid19 heartbeat"
              alt="starsRightDesktop"
            />
          </figure>
          <img
            src={starsRight}
            className="left-star-covid19 heartbeat"
            alt="stars-left"
          ></img>
          <h1 className="title title-covid19 tracking-in-expand-fwd-top">
            Cuidándonos
          </h1>
          <img
            src={starsRight}
            className="right-star-covid19 heartbeat"
            alt="stars-right"
          ></img>
        </div>
      </div>
      {/* <div
        id="tittle-covid19"
        className="box-title-covid19 animated fadeIn mt-3"
      >
        <div>
          <img
            src={starsLeftDesktop}
            className="stars heartbeat"
            alt="stars-left"
          />
          <img
            src={starsRightMobile}
            className="stars-mobile heartbeat"
            alt="stars-left"
          />
        </div>
        <div className="title-container">
          <h1 className="title-covid19 tracking-in-expand-fwd-top">
            Cuidándonos
          </h1>
          <p className="main-text tracking-in-expand-fwd-top">
            Descubre nuestros recursos para acompañarte durante esta época
            difícil por la emergencia sanitaria del Covid-19. ¡Continua
            aprendiendo desde casa actividades que fortalecen el desarrollo
            integral de tus hijas e hijos!
          </p>
        </div>
        <div>
          <img
            src={starsRightDesktop}
            className="stars heartbeat"
            alt="stars-right"
          />
          <img
            src={starsLeftMobile}
            className="stars-mobile heartbeat"
            alt="stars-right"
          />
        </div>
      </div> */}
      <div className="banner-stars">
        <p className="main-text tracking-in-expand-fwd-top">
          La emergencia sanitaria del Covid-19 ha representado <br /> muchos
          cambios y, si bien las niñas, niños y jóvenes no son <br /> los más
          afectados por el contagio, su crianza y desarrollo sí <br /> se ven
          afectados.
        </p>
        <div>
          <img
            src={require(`../../images/covid19/vector.png`)}
            alt="stars-left"
          />
        </div>
        <p className="main-text tracking-in-expand-fwd-top">
          Por ello, queremos acompañarte durante esta <br /> época dificil con
          consejos y herramientas <br /> prácticas. ¡Continúa fortaleciendo el
          desarrollo <br /> integral de tus hijas e hijos con actividades que{" "}
          <br /> puedes implementar desde casa!
        </p>
      </div>
      <div className="banner-fondo">
        <div className="btns-container">
          <div className="btn-div">
            <div>
              <img
                className="banner-icon heartbeat"
                src={playIcon}
                alt="playIcon"
                onClick={handleShow}
                style={{
                  boxShadow: "3px 4px 0px #588b8a",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
              />
            </div>
            <a
              onClick={handleShow}
              target="_blank"
              rel="noopener noreferrer"
              className="btn-covid19 btn-play"
            >
              <Modal
                dialogClassName="my-modal"
                show={show}
                onHide={handleClose}
              >
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-text-title">
                  Aprende con videos cortos y sencillos cómo impulsar el <br />
                  desarrollo y la educación de tus hijas e hijos.
                </div>
                <Modal.Body>
                  <div className="row">
                    <div className="column">
                      <div
                        className="rectangle-1"
                        onClick={() =>
                          handleShow2(
                            "Cuidándonos",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI2zi7Xbsjf06APxcQrHY2Px"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={cuidandonos}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Cuidándonos
                          </div>
                        </div>
                      </div>
                      <a
                        className="rectangle-1"
                        onClick={() =>
                          handleShow2(
                            "Higiéne & Agua segura (0 a 5 años)",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI1bW22lAAK9iUbv-g84qQrG"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img width="50%" left="25%" src={agua} alt="play" />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Higiéne & Agua segura (0 a 5 años)
                          </div>
                        </div>
                      </a>
                      <div
                        className="rectangle-1"
                        onClick={() =>
                          handleShow2(
                            "Apoyando en desarrollo y aprendizaje en Inicial",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI03kR45hqeX8WARD37R8eof"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={aprender}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Apoyando en desarrollo y<br /> aprendizaje en
                            Inicial
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div
                        className="rectangle-2"
                        onClick={() =>
                          handleShow2(
                            "Socioemocional (0 a 5 años)",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI02dfOWOrX6PnU1FgVXx1Wz"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={cuidandonos}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Socioemocional (0 a 5 años)
                          </div>
                        </div>
                      </div>
                      <div
                        className="rectangle-2"
                        onClick={() =>
                          handleShow2(
                            "Nutrición para el desarrollo integral (0 a 5 años)",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI1NjNOBz4KhNfmV27_LRQV4"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={nutrition}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Nutrición para el desarrollo integral (0 a 5 años)
                          </div>
                        </div>
                      </div>
                      <div
                        className="rectangle-2"
                        onClick={() =>
                          handleShow2(
                            "Apoyando en desarrollo y aprendizaje en Primaria",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI0gsNzKInFYPt3BRnn5Wwd4"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={aprender}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Apoyando en desarrollo y aprendizaje en Primaria
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column">
                      <div
                        className="rectangle-3"
                        onClick={() =>
                          handleShow2(
                            "Aprovechando las rutinas (0 a 5 años)",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI1AAd-x4Q8tIJPoPunc0KAl"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={cuidandonos}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Aprovechando las rutinas (0 a 5 años)
                          </div>
                        </div>
                      </div>
                      <div
                        className="rectangle-3"
                        onClick={() =>
                          handleShow2(
                            "Fomentemos el juego (0 a 5 años)",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI3grei8Kspy5vQhowm4h0ce"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={games}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Fomentemos el juego (0 a 5 años)
                          </div>
                        </div>
                      </div>
                      <div
                        className="rectangle-3"
                        onClick={() =>
                          handleShow2(
                            "Apoyando en desarrollo y aprendizaje en Secundaria",
                            "https://www.youtube.com/embed/videoseries?list=PLrxQFO0SRaI0NH-L0KNbXAwuIUBVMiLHG"
                          )
                        }
                      >
                        <div className="row-small">
                          <div
                            className="column play-icon"
                            style={{ flex: "0 0 30%" }}
                          >
                            <img
                              width="50%"
                              left="25%"
                              src={aprender}
                              alt="play"
                            />
                          </div>
                          <div
                            className="column2 boxes-text"
                            style={{ flex: "0 0 70%" }}
                          >
                            Apoyando en desarrollo y aprendizaje en Secundaria
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
              </Modal>
              <Modal
                dialogClassName="my-modal"
                show={show2}
                onHide={handleClose2}
              >
                <Modal.Header closeButton></Modal.Header>
                <div className="modal-text-title">{title}</div>
                <h className="boxes-text-blue-modal">
                  Recuerda que para acceder a la lista de reproducción debes
                  presionar el icono &nbsp;
                  <svg
                    width="36"
                    height="36"
                    viewBox="0 0 36 36"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23.7383 31.878V21.2227L32.3445 27.0968L23.7383 31.878Z"
                      fill="#2E335E"
                    />
                    <rect
                      x="3.65625"
                      y="4.07812"
                      width="23.4964"
                      height="4.50804"
                      fill="#2E335E"
                    />
                    <rect
                      x="3.65625"
                      y="12.2744"
                      width="23.4964"
                      height="4.50804"
                      fill="#2E335E"
                    />
                    <rect
                      x="3.65625"
                      y="20.4707"
                      width="16.2562"
                      height="4.50804"
                      fill="#2E335E"
                    />
                  </svg>{" "}
                  que aparece en la parte superior del video
                </h>

                <Modal.Body className="frame">
                  <iframe width="582" height="400" src={url}></iframe>
                </Modal.Body>
                <div className="footer-center">
                  <div className="btn-div">
                    <a
                      className="btn-covid19 btn-moreInfo"
                      onClick={handleClose2}
                    >
                      <div className="btn-content">
                        <img
                          src={arrowLeft}
                          className="arrow-icon"
                          alt="arrowLeft"
                        />{" "}
                        <p
                          className="color-text"
                          style={{ margin: "0px 20px" }}
                        >
                          REGRESAR{" "}
                        </p>
                        <img
                          src={arrowRight}
                          className="arrow-icon"
                          alt="arrowRight"
                        />
                      </div>
                    </a>
                  </div>
                </div>
              </Modal>
              <div className="btn-covid19 btn-content">
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ margin: "0px 20px" }}>VIDEOS </p>
                <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
              </div>
            </a>
            <div className="banner-text-und ">
              <p>
                Aprende con videos cortos y sencillos cómo impulsar el desarrolo
                y la educación de tus hijas e hijos.
              </p>
            </div>
          </div>
          <div className="btn-div">
            <div>
              <Link className="elipse-container" to="/covid19/consejos">
                <img
                  className="banner-icon heartbeat"
                  src={adviceIcon}
                  alt="adviceIcon"
                  style={{
                    boxShadow: "3px 4px 0px #7a697d",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </div>
            <Link to="/covid19/consejos" className="btn-covid19 btn-advice">
              <div className="btn-content">
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ margin: "0px 15px" }}> CONSEJOS </p>
                <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
              </div>
            </Link>
            <div className="banner-text-und">
              <p>
                Estos consejos te ayudarán a ti y tu familia a seguir cuidándose
                en esta coyuntura.
              </p>
            </div>
          </div>
          <div className="btn-div">
            <div>
              <Link
                className="elipse-container"
                to="/aprendamos/aliado/docente/recursos-extras"
              >
                <img
                  className="banner-icon heartbeat"
                  src={resourcesIcon}
                  alt="resourcesIcon"
                  style={{
                    boxShadow: "3px 4px 0px #a53255",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
              </Link>
            </div>
            <Link
              to="/aprendamos/aliado/docente/recursos-extras"
              className="btn-covid19 btn-resources"
            >
              <div className="btn-content">
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ width: "8rem", margin: "0px 2px" }}>RECURSOS </p>
                <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
              </div>
            </Link>
            <div className="banner-text-und">
              <p>
                Encuentra aquí herramientas digitales para apoyar la educación a
                distancia de tus hijas e hijos.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="new-section">
        <div className="new-section-row">
          <div className="new-section-text">
            Frente a la coyuntura por el covid-19, desde Volar diseñamos y
            desplegamos diversas intervenciones para mitigar los efectos
            negativos de la pandemia en el desarrollo infantil temprano y la
            educación de niñas, niños y jóvenes. Estas se centraron en dos
            frentes:
          </div>
        </div>
        <div className="new-section-row">
          <div className="new-section-container">
            <div className="new-section-item new-section-title">
              <div style={{ width: "fit-content", textAlign: "left" }}>
                <p onClick={handleShow3} className="new-plus-text">
                  + Comunidades <br /> BRECA
                </p>
              </div>
            </div>
          </div>
          <div className="new-section-container ">
            <div className="new-section-item">
              <div style={{ width: "fit-content", textAlign: "left" }}>
                <p onClick={handleShow4} className="new-plus-text2">
                  + Colaboradores <br /> BRECA
                </p>
              </div>
            </div>
          </div>
        </div>
        <Modal scrollable={true} dialogClassName="my-modal" show={show3} onHide={handleClose3}>
          <ModalHeader closeButton></ModalHeader>
          <Modal.Body>
            <div className="info-container">
              <div className="info-item info-title">
                <div style={{ width: "fit-content", textAlign: "left" }}>
                  <p className="info-main-text" style={{ fontSize: "35px" }}>
                    Variante{" "}
                  </p>
                  <p className="info-plus-text " style={{ fontSize: "35px" }}>
                    + Comunidades
                    <br /> BRECA
                  </p>
                </div>
              </div>
              <div className="info-item info-graphics">
                <div style={{ display: "flex", gap: "15px" }}>
                  <p className="info-number">1.</p>
                  <div>
                    <img src={logo} className="info-logo" alt="logo" />
                    <p className="info-text" style={{ color: "#CCCCFF" }}>
                      emergencia
                    </p>
                    <p className="info-text" style={{ color: "#FFC000" }}>
                      Docentes y directivos
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <InfoItem
                    icon={smsIcon}
                    text="SMS según nivel de enseñanza"
                    color="#47CCCD"
                  />
                  <InfoItem
                    icon={peopleIcon}
                    text="Formación docente"
                    color="#47CCCD"
                  />
                  <InfoItem
                    icon={workshopsIcon}
                    text="Videos y recursos educativos"
                    color="#47CCCD"
                  />
                  <InfoItem
                    icon={internetIcon}
                    text="Tablets y megas para acceso a internet y recursos"
                    color="#A78BD2"
                  />
                </div>
              </div>
              <div className="info-item info-graphics">
                <div style={{ display: "flex", gap: "15px" }}>
                  <p className="info-number">2.</p>
                  <div>
                    <img src={logo} className="info-logo" alt="logo" />
                    <p className="info-text" style={{ color: "#CCCCFF" }}>
                      emergencia
                    </p>
                    <p className="info-text" style={{ color: "#FFC000" }}>
                      Hogares
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <InfoItem
                    icon={smsIcon}
                    text="SMS según edad del hijo/a"
                    color="#47CCCD"
                  />
                  <InfoItem
                    icon={workshopsIcon}
                    text="Videos y recursos de apoyo educativo y crianza"
                    color="#47CCCD"
                  />
                  <InfoItem
                    icon={meetingsIcon}
                    text="Talleres virtuales"
                    color="#47CCCD"
                  />
                  <InfoItem
                    icon={internetIcon}
                    text="Tablets y megas para acceso a internet y recursos"
                    color="#A78BD2"
                  />
                </div>
              </div>


            </div>
            {/*-------------------------------------------------------------------------------------------------------------------------------------*/}
            <div className="modal-container">
              <p className="nosotros-covid-title" style={{ color: "#A78BD2" }}>
                Resultados
              </p>
              <br />
              <p className="nosotros-covid-text" style={{ color: "#2E335E" }}>
                En el caso de{" "}
                <strong>Volar frente a la emergencia – Comunidades</strong> BRECA
                logramos:
              </p>
              <br />
              <div className="results-container">
                <div className="results-item nosotros-covid-text results-purple">
                  Mejora en el tiempo de apoyo la educación los hijo/as por semana
                </div>
                <div className="results-item nosotros-covid-text results-purple">
                  Mejora en el acceso a plataforma Aprendo en Casa
                </div>
                <div className="results-item nosotros-covid-text results-purple">
                  Reducción de cuidadores que no saben cómo apoyar la educación de sus
                  hijos/as
                </div>
                <div className="results-item nosotros-covid-text results-purple">
                  Mejora en la comunicación hogar- docente en frecuencia y calidad
                </div>
                <div className="results-item nosotros-covid-text results-purple">
                  Incremento de cuidadores que juega o lee con sus hijos/as menores de
                  5 años
                </div>
                <div className="results-item nosotros-covid-text results-purple">
                  Incremento del porcentaje de estudiantes que recibe feedback
                  adecuado de sus docentes
                </div>
                <div className="results-item nosotros-covid-text results-purple">
                  Mejora en las capacidades y estrategias de acompañamiento
                </div>
                <div className="results-item nosotros-covid-text results-purple">
                  El Social ROI para Volar frente a la emergencia 2020, que refleja el
                  retorno de la inversión Puno fue de 36%.
                </div>
              </div>
            </div>
            {/*-------------------------------------------------------------------------------------------------------------------------------------*/}
          </Modal.Body>
        </Modal>
        <Modal scrollable={true} dialogClassName="my-modal" show={show4} onHide={handleClose4}>
          <ModalHeader closeButton></ModalHeader>
          <Modal.Body>
            <div className="info-container">
              <div className="info-item info-title">
                <div style={{ width: "fit-content", textAlign: "left" }}>
                  <p className="info-main-text" style={{ fontSize: "35px" }}>
                    Variante{" "}
                  </p>
                  <p className="info-plus-text2" style={{ fontSize: "35px" }}>
                    + Colaboradores
                    <br /> BRECA
                  </p>
                </div>
              </div>
              <div className="info-item info-graphics">
                <InfoItem
                  icon={smsIcon}
                  text="SMS según nivel de enseñanza"
                  color="#47CCCD"
                />
                <br />
                <InfoItem
                  icon={meetingsIcon}
                  text="Talleres virtuales"
                  color="#47CCCD"
                />
              </div>
              <div className="info-item info-graphics">
                <InfoItem
                  icon={workshopsIcon}
                  text="Videos y recursos educativos"
                  color="#47CCCD"
                />
                <br />
                <InfoItem
                  icon={videocallIcon}
                  text="Voluntariado Corporativo"
                  color="#A78BD2"
                />
              </div>
            </div>
            {/*-------------------------------------------------------------------------------------------------------------------------------------*/}
            <div className="modal-container">
              <p className="nosotros-covid-title" style={{ color: "#7DC7C5" }}>
                Resultados
              </p>
              <br />
              <p className="nosotros-covid-text">
                En el caso de{" "}
                <strong style={{ color: "#4CD3D3" }}>
                  Volar frente a la emergencia- Colaboradores
                </strong>{" "}
                BRECA logramos:
              </p>
              <br />
              <div className="results-container">
                <div className="results-item nosotros-covid-text results-blue">
                  Reducción de la preocupación de los colaboradores por la educación
                  de sus hijos/as
                </div>
                <div className="results-item nosotros-covid-text results-blue">
                  Mejora en el acceso a plataformas educativas especializadas
                </div>
                <div className="results-item nosotros-covid-text results-blue">
                  Reducción de la incidencia de problemas de salud mental en
                  colaboradores con hijos/as menores edad
                </div>
                <div className="results-item nosotros-covid-text results-blue">
                  Mejora en hábitos de estudio adecuados
                </div>
                <div className="results-item nosotros-covid-text results-blue">
                  Mejora en sus habilidades para crianza y educación
                </div>
                <div className="results-item nosotros-covid-text results-blue">
                  Mejora en el desempeño en matemáticas y comunicación
                </div>
                <div className="results-item nosotros-covid-text results-blue">
                  Mejoras en la adaptación a la educación a distancia
                </div>
                <div className="results-item nosotros-covid-text results-blue">
                  Mejora la organización del espacio de estudio
                </div>
              </div>
            </div>

            {/*-------------------------------------------------------------------------------------------------------------------------------------*/}


          </Modal.Body>
        </Modal>
        <div className="btn-div" style={{ marginTop: "20px" }}>
          <a href="/nosotros/covid" className="btn-covid19 btn-moreInfo">
            <div className="btn-content">
              <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
              <p style={{ margin: "0px 20px" }}>CONOCE MÁS </p>
              <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
            </div>
          </a>
        </div>
      </div>
      {/*<div className="components-section">
        <p className="section-title components-title">Componentes</p>
        <div className="components-container">
          <Component
            title="Voluntariado corporativo"
            text="Sesiones de acompañamiento educativo para el refuerzo de aprendizajes y desarrollo de competencias en niñas y niños en educación básica regular."
            icon={volunteeringIcon}
          />
          <Component
            title="Talleres Virtuales a familias"
            text="Sesiones pre-grabadas y talleres virtuales en vivo para reforzar el acompañamiento educativo que se da desde casa y potenciar las estrategias de crianza que manejan las familias"
            icon={workshopsIcon}
          />
          <Component
            title="Conectividad Volar"
            text="Entrega de tablets y paquetes de datos móviles a familias en situación de vulnerabilidad para garantizar su acceso a la educación a distancia y a los talleres virtuales del proyecto"
            icon={connectivityIcon}
          />
          <Component
            title="SMS Volar"
            text="Según la edad de las niñas y niños en las familias, se envían mensajes de texto de manera frecuente que brindan consejos para la crianza, la nutrición e higiene, el soporte emocional y el acompañamiento educativo."
            icon={smsIcon}
          />
          <Component
            title="Contenido Volar"
            text="Distribución de recursos y contenidos audiovisuales como videos tips, pastillas educativas, infografías y talleres pre-grabados para fortalecer hábitos de crianza, el apoyo en la educación y el desarrollo de vínculos en las familias."
            icon={contentIcon}
          />
          <Component
            title="Formación docente"
            text="En alianza con la ONG Enseña Perú, se desarrollan talleres con tres ejes de formación para docentes en Puno, estos son Educación Basada en Competencias, Evaluación Formativa y Habilidades Socioemocionales"
            icon={teacherIcon}
          />
        </div>
        <br />
        <br />
        <div className="btn-div">
          <a href="/nosotros/covid" className="btn-covid19 btn-moreInfo">
              <div className="btn-content">
              <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
              <p style={{ margin: "0px 20px" }}>CONOCE MÁS </p>
              <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
              </div>
          </a>
        </div>
      </div>
      <p className="section-title slider-title">¿Qué logramos en el 2020?</p>
      <div className="slider-container">
        <img className="prueba" src={sliderCohete1} alt="sliderCohete1" />
        <div>
          <img
            className="arrow-slider"
            src={arrowLeftSlider}
            alt="arrowLeftSlider"
            onClick={() => prevSlider(current_slider, setCurrent_slider)}
          />
        </div>
        {sliders_content.data.map((slider) => (
          <Slider
            key={slider.key}
            {...slider}
            show={current_slider === slider.key}
          />
        ))}
        <div>
          <img
            className="arrow-slider"
            src={arrowRightSlider}
            alt="arrowRightSlider"
            onClick={() =>
              nextSlider(
                current_slider,
                setCurrent_slider,
                sliders_content.data.length
              )
            }
          />
        </div>
      </div>
      <br />
      <br />
      <div className="btn-div">
        <a
          href="https://covid19.minsa.gob.pe/"
          target="_blank"
          rel="noopener noreferrer"
          className="btn-covid19 btn-moreInfo"
        >
          <div className="btn-content" style={{ padding: "5px 20px" }}>
            <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
            <p style={{ maxWidth: "22rem", margin: "0px 10px" }}>
              REVISA LA INFORMACIÓN DEL GOBIERNO EN RELACIÓN AL COVID-19{" "}
            </p>
            <img src={arrowRight} className="arrow-icon" alt="arrowRight" />
          </div>
        </a>
          </div>*/}
      <br />
    </div>
  );
};
export default Covid19;

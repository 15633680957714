import React from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import "./Fase4.css";
import arrowRigth from "../../../images/arrow-right-orange.svg";
import arrowLeft from "../../../images/arrow-left-orange.svg";
import frentes from "../../../images/3frentes.png";
import leftArrow from "../../../images/nosotros-img/left-arrow-phases.svg";
import sliderTasa from "../../../images/covid19/tasa_slider.svg";
import sliderMinsurfa from "../../../images/covid19/minsur-families_slider.svg";
import { useNosotros } from "../../../customHooks";
import colaboradores_2022 from "../../../content/slider_fase4_2022_colaboradores.json";
import comunidades_2022 from "../../../content/slider_fase4_2022_comunidades.json";
import { Slider } from "@material-ui/core";
import { Slider2 } from "../covid19/Slider2";

const Fase4 = () => {
  let history = useHistory();
  const [data, setData] = useNosotros("7zWW2Zr8ivXROS7r97o6Np");
  const [show2022, setShow2022] = React.useState(false);
  const [comunidades, setIsComunidades] = React.useState(false);
  const [colaboradores, setIsColaboradores] = React.useState(false);
  return (
    <div className="animated fadeIn">
      <div className="fase1 fase4-fondo">
        {/*<a onClick={()=>{history.goBack()}} >
                    <i className="fas fa-chevron-left goBack-fase" aria-hidden="true"></i> 
                </a>*/}

        <div className="flex-fase4">
          <div style={{ width: "5%" }}>
            <a
              href="/nosotros/fase3"
              style={{ display: "flex", alignContent: "flex-start" }}
            >
              <img src={leftArrow} />
            </a>
          </div>
          <div>
            <h6 className="titulo-numero-fase color-white">Fase 4</h6>
            <h5 className="titulo-fase4 tracking-in-expand-fwd-top">
              {data?.fase4.fields.nombreDeLaFase}
            </h5>
            <p className="p-mobile-white w-fase">
              Desde el 2022, “Volar” viene maximizando su impacto para lograr
              escalar a nivel nacional y global teniendo para ello como eje
              principal la recopilación de evidencia rigurosa que permite
              realizar seguimiento, evaluación integral y mejoras de cada
              componente del proyecto. Para maximizar el alcance, también se
              cede en uso de diversos recursos a aliados, se generan alianzas
              con referentes globales y se promueve el acceso a una plataforma
              gratuita e interactiva que permite que las y los cuidadores
              adquieran y refuercen sus conocimientos sobre DIT. Al 2030, se
              tiene como meta impactar en las trayectorias de desarrollo de
              aproximadamente 270,000 gestantes, así como niñas y niños hasta
              los 5 años de edad.
            </p>
          </div>
        </div>
        {/* <p className="mt-2 estrellita-naranja heartbeat">&#10022;</p> */}
        {/* <h6 className="title-dit title-fase3-ajustes">Iniciamos nuestro recorrido en Puno</h6>
                <div className="box-mapa">
                    <figure className="mapa-peru">
                        <img src={peru} alt="" className="w-100"/>
                    </figure>
                    <figure className="mapa-item">
                        <img src={item} alt="" className=" pl-2 w-100"/>
                    </figure>
                </div> */}
      </div>
      <div className="fase4">
        <div
          className="seccion1"
          style={{ flexWrap: "nowrap", flexDirection: "column" }}
        >
          <h6 className="seccion1-title">
            Volar es hoy un proyecto que{" "}
            <mark style={{ color: "#EF8B44", background: "none" }}>
              reúne las 3E
            </mark>
          </h6>
          <div
            className="seccion5-boxes"
            // style={{display:"flex", flexDirection: 'row', flexWrap: 'nowrap' }}
          >
            <div className="seccion5-boxes-new">
              <div className="seccion5-boxes-celeste">Eficiente</div>
              <div className="seccion5-boxes-celeste-text">
                La atención por niña/o tiene un bajo costo ya que se aprovecha
                la tecnología durante la intervención.
              </div>
            </div>

            <div className="seccion5-boxes-new">
              <div className="seccion5-boxes-lila">Evaluable</div>
              <div className="seccion5-boxes-celeste-text">
                Cuenta con una teoría de cambio que guía la implementación y
                evaluación del proyecto. Asimismo, los indicadores priorizados
                para la medición han sido testeados en 2021 con excelentes
                resultados.
              </div>
            </div>

            <div className="seccion5-boxes-new">
              <div className="seccion5-boxes-naranja">Escalable</div>
              <div className="seccion5-boxes-celeste-text">
                No requiere de muchas modificaciones para adaptarse a diversos
                espacios y ser implementado dentro o fuera del país.
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
          }}
        >
          <h6 className="seccion1-title">
            Planteamos la expansión de volar
            <mark style={{ color: "#EF8B44", background: "none" }}>
              en 3 frentes
            </mark>
          </h6>
          <img src={frentes} width="50%" />
          <div>
            Teniendo como base los resultados positivos obtenidos hasta ahora,
            "Volar” es un proyecto que está en la capacidad de expandirse. Ello
            se ha planteado en 3 ejes:
            <br />
            <ul style={{ marginTop: "24px" }}>
              <li>
                En el{" "}
                <span style={{ fontWeight: "700", color: "#19ced7" }}>
                  ecosistema del grupo Breca
                </span>{" "}
                con los grupos de interés más relevantes para cada una de las
                empresas (colaboradores, comunidades de sus áreas de influencia,
                proveedores, entre otros).
              </li>
              <li>
                En el{" "}
                <span style={{ fontWeight: "700", color: "#ffc000" }}>
                  país
                </span>{" "}
                buscando influir en la política pública referente al Desarrollo
                Infantil Temprano.
              </li>
              <li>
                A escala{" "}
                <span style={{ fontWeight: "700", color: "#ac75d5" }}>
                  global
                </span>{" "}
                con soluciones basadas en la evidencia y que aprovechan las TIC
                para impactar positivamente en el desafío complejo del DIT en
                todo el mundo.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            gap: "12px",
            alignItems: "center",
            marginBottom: "24px",
          }}
        >
          {/* <div className='btn-div' style={{ marginTop: '20px' }}> */}
          <div className="btn-div">
            <a
              // href='/nosotros/'
              onClick={() => {
                setShow2022(!show2022);
              }}
              style={{ color: "white" }}
              // style={{ backgroundColor: '#7dc7c5' }}
              className="btn-covid19 btn-moreInfo"
            >
              <div className="btn-content">
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ margin: "0px 20px" }}>2022</p>
                <img src={arrowRigth} className="arrow-icon" alt="arrowRight" />
              </div>
            </a>
          </div>
          <div className="btn-div">
            {/* <div className='btn-div' style={{ marginTop: '20px' }}> */}
            {/* <a href='/nosotros/fase4' className='btn-covid19 btn-moreInfo'> */}
            <a
              style={{ color: "white" }}
              // onClick={() => {
              //   setIs2020(false);
              // }}
              className="btn-covid19 btn-moreInfo"
            >
              <div className="btn-content">
                <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
                <p style={{ margin: "0px 20px" }}>2023</p>
                <img src={arrowRigth} className="arrow-icon" alt="arrowRight" />
              </div>
            </a>
          </div>
        </div>
      </div>
      {show2022 && (
        <div className="new-section-row" style={{ display: "flex", justifyContent: 'normal'  }}>
          <div className="new-section-container" style={{ width: "50%" }}>
            <div
              className="new-section-item new-section-title"
              style={{ borderRight: "0px", justifyContent: "flex-end" }}
            >
              <div style={{ width: "fit-content" }}>
                <div
                  // onClick={() => setShowComunidades(true)}
                  className="new-plus-text"
                >
                  {/* <p className='new-plus-text'> */}
                  + Comunidades <br /> BRECA
                </div>
                <div
                  className="slider-img"
                  // onClick={() => {
                  //   handleChange(1)
                  //   handleShow()
                  // }}
                  style={{ display: "flex", justifyContent: "center" }}
                  onClick={() => setIsComunidades(true)}
                >
                  <div className="blank-box-color">
                    <img
                      className="image-show"
                      src={sliderMinsurfa}
                      alt="minsur"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ borderRight: "2px dashed #ef8b44" }}></div>
          <div className="new-section-container ">
            <div className="new-section-item">
              <div style={{ width: "fit-content" }}>
                <div
                  onClick={() => setIsColaboradores(true)}
                  className="new-plus-text2"
                >
                  {/* <p className='new-plus-text2'> */}
                  + Colaboradores <br /> BRECA
                </div>
                <div
                  style={{ display: "flex", justifyContent: "center" }}
                  className="slider-img"
                  onClick={() => setIsColaboradores(true)}
                >
                  <div className="blank-box-color">
                    <img className="image-show" src={sliderTasa} alt="tasa" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="next-phase-background" style={{ marginTop: "20px" }}>
        <a href="" className="next-phase-btn wobble-hor-bottom">
          <img src={arrowLeft} className="arrow-icon" alt="arrowLeft" />{" "}
          <p
            className="next-phase-btn-text"
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "0px 2px",
              fontSize: "1.2rem",
            }}
          >
            Descargar Memoria
          </p>
          <img src={arrowRigth} className="arrow-icon" alt="arrowRight" />
        </a>
      </div>
      <Modal
        scrollable={true}
        dialogClassName="my-modal"
        show={colaboradores}
        onHide={() => setIsColaboradores(!colaboradores)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <div> */}
          {/* <h1 */}
          {/*   style={{ textAlign: "center", color: "#2E335E", fontWeight: 900 }} */}
          {/* > */}
          {/*   COLABORADORES */}
          {/* </h1> */}
          {show2022 &&
            colaboradores_2022.data.map((slider) => (
              <Slider2 key={slider.key} {...slider} show={show2022} />
            ))}
          {/* <h1 */}
          {/*   style={{ textAlign: "center", color: "#2E335E", fontWeight: 900 }} */}
          {/* > */}
          {/*   COMUNIDADES */}
          {/* </h1> */}
        </Modal.Body>
      </Modal>
      <Modal
        scrollable={true}
        dialogClassName="my-modal"
        show={comunidades}
        onHide={() => setIsComunidades(!comunidades)}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {/* <div> */}
          {/* <h1 */}
          {/*   style={{ textAlign: "center", color: "#2E335E", fontWeight: 900 }} */}
          {/* > */}
          {/*   COLABORADORES */}
          {/* </h1> */}
          {/* {colaboradores_2022.data.map((slider) => ( */}
          {/*   <Slider2 key={slider.key} {...slider} show={show2022} /> */}
          {/* ))} */}
          {/* <h1 */}
          {/*   style={{ textAlign: "center", color: "#2E335E", fontWeight: 900 }} */}
          {/* > */}
          {/*   COMUNIDADES */}
          {/* </h1> */}
          {show2022 &&
            comunidades_2022.data.map((slider) => (
              <Slider2 key={slider.key} {...slider} show={show2022} />
            ))}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Fase4;

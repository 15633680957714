import React from 'react'
import { recursiveProcessContenido } from "../../../../../utils"

const cssStyle = {

  'Inicial': {
    'div': {
      'className': 'btn-inicial'
    },
    'h2': {
      'on': "title-btn-inicial",
      'off': "title-btn-inicial title-none-inicial"
    },
    'a': {
      'classNameBlanco': 'btn-blanco-inicial',
      'classNameColor': 'btn-morado-inicial'
    }
  },
  'Secundaria': {
    'div': {
      'className': 'btn-secundaria'
    },
    'h2': {
      'on': "title-btn-secundaria",
      'off': "title-btn-secundaria title-none-secundaria"
    },
    'a': {
      'classNameBlanco': 'btn-blanco-secundaria',
      'classNameColor': 'btn-amarillo-secundaria'
    }
  },
  'Primaria': {
    'div': {
      'className': 'btn-primaria'
    },
    'h2': {
      'on': "title-btn-primaria",
      'off': "title-btn-primaria title-none-primaria"
    },
    'a': {
      'classNameBlanco': 'btn-blanco-primaria',
      'classNameColor': 'btn-fuxia-primaria'
    }
  }

}


const BlockContent = ({ data, grado, orden }) => {
  const reorderContent = recursiveProcessContenido(data.fields.recursoExtra)
  {/* console.log("reorderContent", reorderContent, 'data', data) */ }
  const style = cssStyle[grado]
  {/* console.log("orden", orden) */ }
  return (
    <>
      {
        reorderContent.map((item, index) => {
          {/* console.log("index", index) */ }
          return (
            <React.Fragment key={index}>
              {index === 0 ? (
                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <div className={style.div.className}>
                    <h2 className={style.h2.on}>{data.fields.nombre}</h2>
                    {item.map((link, i) => {
                      {/* console.log('orden2', link) */ }
                      return (
                        <a
                          href={link.fields.url}
                          target="_blank"
                          className={"" + (orden % 2 === 0 ? style.a.classNameColor : style.a.classNameBlanco)}
                          key={i}
                        >
                          {link.fields.titulo}
                        </a>
                      )
                    })}
                  </div>
                </div>
              ) : (

                <div className="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                  <div className={style.div.className}>
                    <h2 className={style.h2.off} style={{ opacity: '0' }}>.</h2>
                    {item.map((link, i) => {
                      return (
                        <a
                          href={link.fields.url}
                          target="_blank"
                          className={"" + (orden % 2 === 0 ? style.a.classNameColor : style.a.classNameBlanco)}
                          key={i}
                        >
                          {link.fields.titulo}
                        </a>
                      )
                    })}
                  </div>
                </div>

              )
              }
            </React.Fragment >
          )

        })
      }
    </>
  )

}

export default BlockContent

import React from 'react'
import { Button, Modal } from "react-bootstrap";

const Infografia = ({ tipoMaterial }) => {
  const [showModal, setShowModal] = React.useState(false)

  const show = () => setShowModal(true)
  const hide = () => setShowModal(false)

  return (
    <>
      <button
        className="btn-modulos-info"
        onClick={() => setShowModal(true)}
      >
        <figure className="btn-infografia">
          {!tipoMaterial.fields.imagenIconoMaterial ? "" : <img src={tipoMaterial.fields.imagenIconoMaterial.fields.file.url} alt="" className="w-100" />}
        </figure>
        <div className="btn-info-text">{!tipoMaterial.fields.titulo ? <p>Titulo no ingresado</p> : tipoMaterial.fields.titulo}</div>
      </button>
      {showModal && <Modal show={show} onHide={hide}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="container animated fadeIn">
            <div className="">
              <div>
                <div className="row">
                  <div className="col-12">
                    {!tipoMaterial.fields.imageInfografia ? <p>Infografia no cargada </p> : <img className="img-infografia" src={tipoMaterial.fields.imageInfografia.fields.file.url} alt="" />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>}
    </>


  )
}

export default Infografia

import React from 'react'
import arrowLeft from "./../../../../images/arrow-left-blue.svg";
import arrowRight from "./../../../../images/arrow-right-blue.svg";
import { Button, Modal } from "react-bootstrap";
import { Link } from 'react-router-dom'

const ModuloModal = ({ indexSelection, onHide, data, showModulo }) => {
  return (
    <Modal show={showModulo} onHide={onHide}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="container animated fadeIn">
          <div className="">
            <div>
              <div className="row">
                <div className="col-12">
                  <h4 className="tittle-letter-modal">{data[indexSelection].fields.tituloModal}</h4>
                  <p className="letter-modal">{data[indexSelection].fields.subtituloModal}</p>
                </div>
              </div>
              <div className="caja-boton-rol">
                <Link
                  type="submit"
                  className="btn-navy-blue font-white mt-3 mb-3 wobble-hor-bottom out-none-button link"
                  data-dismiss="modal"
                  to={{ pathname: "/aprendamos/aliado/modulos/modulo_1", state: { data: data[0] } }}
                >
                  <img src={arrowLeft} className="arrow-blue"></img>
                  VER MÁS AQUÍ
                  <img src={arrowRight} className="arrow-blue"></img>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal >
  )
}

export default ModuloModal;

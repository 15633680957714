import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import "./consejoId.css";

import advices_content from "../../../content/advices.json";

import leftArrow from "../../../images/nosotros-img/left-arrow-phases.svg";

const ConsejoId = ({ match }) => {
  const { id } = match.params;
  const [advice, setAdvice] = useState({});
  const location = useLocation();
  const { data } = location.state;

  return (
    <div id="covid19-consejoId-page">
      <div
        id="tittle-covid19-adviceId"
        className="covid19-adviceId animated fadeIn"
      >
        <div>
          <Link to="/covid19/consejos">
            <img
              className="adviceId-leftArrow"
              src={leftArrow}
              alt="leftArrow"
            />
          </Link>
        </div>
        <div className="adviceId-text-container">
          <h1 className="adviceId-title tracking-in-expand-fwd-top">
            {data.nombre}
          </h1>
          <p className="adviceId-tip">TIP N° {parseInt(id) + 1}</p>
          {/* <p className="adviceId-text">{localStorage.getItem("nameUserActive") ? data.descripcion.replace("{{usuario}}", localStorage.getItem("nameUserActive"))  }</p> */}
          <p className="adviceId-text">{data.descripcion}</p>
          {data.tipo === true ? (
            <img
              className="adviceId-icon tracking-in-expand-fwd-top"
              src={data.icono.fields.file.url}
              alt={"Imagentipo"}
            />
          ) : (
            <div className="adviceId-video">
              <div className="adviceId-video-responsive">
                <iframe
                  src={data.urlVideo}
                  title={data.nombre}
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen={true}
                ></iframe>
              </div>
            </div>
          )}
          <p className="adviceId-text">{data.mensajeNegrita}</p>
        </div>
        <div></div>
      </div>
      <br />
    </div>
  );
};
export default ConsejoId;

import React, { Fragment } from "react";

import fabiola from "../../images/equipo/fabiola_1.png";
import sara from "../../images/equipo/sara_2.png";
import geraldine from "../../images/equipo/geraldine_3.png";
import aran from "../../images/equipo/arturo_4.png";
import maria from "../../images/equipo/guadalupe_5.png";
import natalia from "../../images/equipo/natalia_6.png";
import karina from "../../images/equipo/karina_7.png";
import { getContentfulData, recursivePhotoGroup, recursivePhotoGroupMobile } from "../../utils"

const CarouselMobile = ({ data }) => {
  return (
    <>
      <div className="carousel-inner" role="listbox">

        {data.equipo ? recursivePhotoGroupMobile(data.equipo) : []}

      </div>
    </>
  );
};

export default CarouselMobile;
